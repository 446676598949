import { lazy, useEffect, useRef, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Location from '@/classes/Location';
import Organization from '@/classes/Organization';
import User, { UserProfile } from '@/classes/User';

import firebase, { db, fbAnalytics, fbAuth, fbFunctions } from '@/lib/firebase';
import { logEvent } from 'firebase/analytics';
import { FirebaseError } from 'firebase/app';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import type { AuthErrorCodes } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';

import { stringToProfileName } from '@/lib/helpers';
import generateCreatedByMeta from '@/lib/helpers/generateMeta';
import isFuzzyTextMatch from '@/lib/helpers/isFuzzyTextMatch';

import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { usePostHog } from 'posthog-js/react';
import { object, string } from 'yup';

import usePageView from '@/hooks/usePageView';
import useUrlQuery from '@/hooks/useUrlQuery';

import { Input, InputError, PasswordInput } from '@/components/formElements/FormElements';

import PrimaryButton from '@/components/common/Buttons';
import ErrorSuspendPlaceholder from '@/components/common/ErrorSuspendPlaceholder';
import { FormLabel } from '@/components/common/Label';
import Loader from '@/components/common/Loader';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';

import NotebirdLogo from '@/components/svg/NotebirdLogo';

// Lazy load illustration
const SignupIllustration = lazy(() => import('../../svg/SignupIllustration'));

// Schema
const schema = object().shape({
  password: string()
    .required('Password is required')
    .min(8, 'Not long enough. 8 characters minimum')
    .max(250, '250 characters max'),
  email:
    import.meta.env.MODE === 'production'
      ? string()
          .trim()
          .email('Please enter a valid email address')
          .required('Email address is required')
          .max(250, '250 characters max')
      : string()
          .trim()
          .email('Please enter a valid email address')
          // Only allow notebird emails in staging environment
          .matches(
            /(@notebird\.app|@notebird\.dev)/,
            'A valid @notebird.app or @notebird.dev email is required on staging.'
          )
          .required('Email address is required')
          .max(250, '250 characters max'),
  name: string().trim().required('Name is required').max(1000, '1000 characters max'),
});

// Styles
const StyledSignupIllustration = styled(SignupIllustration)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: calc(50vw - 256px);
  max-height: 384px;

  @media screen and (max-width: 850px) {
    display: none;
  }
`;
const PageWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100vw;

  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;
`;
const LogoWrapper = styled.div`
  width: 192px;
  margin: 48px auto 48px;
`;
const Head = styled.div`
  margin-bottom: 14px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: ${props => props.theme.textSecondary};
`;
const Subhead = styled.div`
  margin-bottom: 32px;
  text-align: center;
  color: ${props => props.theme.textTertiary};
`;
const SignUpPanel = styled.div`
  position: relative;
  background-color: ${props => props.theme.sheetBackgroundColor};
  border-radius: 6px;
  margin: 0 auto 48px;
  width: 384px;
  max-width: 100%;
  max-width: 100vw;
  box-shadow: ${props => props.theme.shadow400};
`;
const InputErrorExtra = styled.div`
  font-weight: bold;
  margin-top: 4px;
  color: ${props => props.theme.textTertiary};
`;
const Agreement = styled.div`
  margin-top: 16px;
  text-align: center;
  color: ${props => props.theme.textFaded};
  font-size: 12px;
  line-height: 1.4;
  a {
    color: ${props => props.theme.textFaded};
    text-decoration: underline;
  }
`;
const Footer = styled.div`
  position: relative;
  text-align: center;
  font-weight: bold;
  color: ${props => props.theme.textTertiary};
  line-height: 1.4;
`;

// Component
export default function SignUpPage() {
  // Register page view
  usePageView({ title: 'Sign Up | Notebird' });
  const posthog = usePostHog();

  // If user visits https://.../signup/$email, their email will populate
  // in the email field then redirect back to the root
  const { email = '' } = useParams();
  // Potential org type brought over from landing page
  const { orgType } = useUrlQuery();

  // Form helper (Formik)
  const { values, errors, setFieldError, submitCount, handleSubmit, isSubmitting, getFieldProps } =
    useFormik({
      initialValues: {
        name: '',
        email,
        password: '',
      },
      onSubmit,
      validationSchema: schema,
    });

  // Refs to target focus
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  // Simple (email in use) error state tracker
  const [emailInUse, setEmailInUse] = useState('');

  // Invitation already exists for this email address
  const [existingInvitationEmail, setExistingInvitationEmail] = useState('');

  // Submit
  async function onSubmit() {
    try {
      // Reset potential errors
      setEmailInUse('');
      setExistingInvitationEmail('');

      // Check and make sure password isn't just user's email address
      if (isFuzzyTextMatch(values.password, values.email)) {
        setFieldError('password', 'Password cannot be the same as your email address');
        return;
      }

      // Check to make sure an invitation doesn't already exist for this email address
      const doesInvitationExist = await httpsCallable(
        fbFunctions,
        'doesInvitationExist'
      )({
        email: values.email.toLowerCase().trim(),
      });
      if (doesInvitationExist.data) {
        setExistingInvitationEmail(values.email.trim());
        posthog?.capture('user_invitation_already_exists', {
          page: 'Sign Up',
          attempts: submitCount,
        });
        logEvent(fbAnalytics, 'invitation_already_exists', {
          page: 'sign_up',
          login_attempts: submitCount,
        });
        return;
      }

      // Create auth account
      const { user } = await createUserWithEmailAndPassword(
        fbAuth,
        values.email.trim(),
        values.password
      );
      if (user) {
        // UID
        const uid = user.uid;
        // Batch create organization plus user within it
        const batch = db.batch();
        // Establish doc refs
        const userRef = db.collection('users').doc(uid);
        const orgRef = db.collection('organizations').doc();
        // Set user data
        const name = stringToProfileName(values.name);
        const email = values.email.trim();
        const newUser: User = {
          ...new User(),
          _signedUpAt:
            firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
          profile: {
            ...new UserProfile(),
            email,
            name: {
              ...name,
              display: [name.first, name.last[0]].join(' ').trim(),
            },
          },
        };
        batch.set(userRef, newUser);
        // Set org data
        const organization: Organization = {
          ...new Organization(),
          meta: generateCreatedByMeta({ id: uid, docPath: userRef.path, ...newUser }),
          owner: {
            id: uid,
            profile: newUser.profile,
          },
          admins: [uid],
          users: [uid],
          restrictedAccessUsers: [uid],
          // Start organization name as user's name
          profile: {
            ...new Organization().profile,
            name: name.full,
          },
        };
        batch.set(orgRef, organization);

        // Commit batch
        await batch.commit();

        // Go ahead and create first location too ( name will be updated during onboarding )
        const locationData: Location = {
          ...new Location(),
          meta: generateCreatedByMeta({ id: uid, docPath: userRef.path, ...newUser }),
          name: name.full,
        };
        await orgRef.collection('locations').doc().set(locationData);

        // Send verificaton email
        httpsCallable(fbFunctions, 'verificationEmail')();
        // Log sign_up analytics event
        posthog?.capture('user_sign_up', { method: 'Email' });
        logEvent(fbAnalytics, 'sign_up', { method: 'email' });
      } else {
        alert(`Unknown sign up error. Please contact support.\n\nUser not found.`);
      }
    } catch (error) {
      // Deal with any errors that arise
      // (the most common one with be '...email-already-in-use
      //    because we deal with the other two in the schema)
      if (error instanceof FirebaseError) {
        type ErrorCode = (typeof AuthErrorCodes)[keyof typeof AuthErrorCodes];
        const code = error.code as ErrorCode;
        switch (code) {
          case 'auth/invalid-email':
            setFieldError('email', 'Please enter a valid email address');
            break;
          case 'auth/email-already-in-use':
            setEmailInUse(values.email.trim());
            posthog?.capture('user_email_in_use', { attempts: submitCount });
            logEvent(fbAnalytics, 'email_in_use', { login_attempts: submitCount });
            break;
          case 'auth/weak-password':
            setFieldError('password', "Password isn't strong enough.");
            break;
          default:
            alert(`Unknown login error. Please contact support.\n\nError code: ${error?.code}`);
        }
      } else {
        alert(`Unknown login error. Please contact support.\n\nError: ${JSON.stringify(error)}`);
      }
      console.error(error);
    }
  }

  // Only allow submissions when isn't already submitting,
  // name/email/password aren't empty,
  // emailInUse/existingInvitationEmail doesn't match,
  // and if has submitted, no errors are present
  // (used to determine disabled state of submit button below)
  const submitDisabled =
    isSubmitting ||
    !values.name.trim() ||
    !values.email.trim() ||
    !values.password ||
    values.password.length < 8 ||
    values.email.trim() === emailInUse ||
    values.email.trim() === existingInvitationEmail ||
    (!!submitCount && !isEmpty(errors));

  // Listen for when user submits focus any error fields
  // once state has flushed and triggered re-render (defer)
  useEffect(() => {
    if (submitCount && !isSubmitting) {
      if (errors.name) {
        nameRef.current && nameRef.current.focus();
      } else if (errors.email) {
        emailRef.current && emailRef.current.focus();
      } else if (errors.password) {
        passwordRef.current && passwordRef.current.focus();
      }
    }
  }, [submitCount, isSubmitting, errors.name, errors.email, errors.password]);

  return (
    <>
      {/* Clear email from URL (but it will remain in state to populate email field) */}
      {!!email && <Navigate to={orgType ? `/signup/?orgType=${orgType}` : '/signup'} replace />}

      {/* Pretty picture */}
      <ErrorSuspendPlaceholder style={{ display: 'none' }}>
        <StyledSignupIllustration tint className='fade-in' />
      </ErrorSuspendPlaceholder>

      <PageWrapper>
        {/* Logo */}
        <LogoWrapper>
          <NotebirdLogo width='192' tint />
        </LogoWrapper>

        {/* Heading copy */}
        <Head>Get started with a free 14-day trial</Head>
        <Subhead>
          Record life events and remember the details.
          <br />
          Do it all with Notebird&apos;s relationship management tools.
        </Subhead>

        {/* Main Panel */}
        <SignUpPanel>
          {/* Loader */}
          <Loader show={isSubmitting} />

          <Padding padding='32px 24px'>
            {/* Form */}
            <form onSubmit={handleSubmit} noValidate>
              {/* Name */}
              <FormLabel htmlFor='name'>Full name</FormLabel>
              <Input
                ref={nameRef}
                autoComplete='name'
                disabled={isSubmitting}
                autoFocus={true}
                {...getFieldProps({ name: 'name' })}
                error={!!submitCount && !!errors.name}
                maxLength={1200}
              />
              {!!submitCount && !!errors.name && <InputError>{errors.name}</InputError>}
              <Spacer height='24px' />

              {/* Email */}
              <FormLabel htmlFor='email'>Email address</FormLabel>
              <Input
                type='email'
                ref={emailRef}
                autoComplete='email'
                disabled={isSubmitting}
                {...getFieldProps({ name: 'email' })}
                error={!!submitCount && !!errors.email}
                maxLength={300}
              />
              {!!submitCount && !!errors.email && <InputError>{errors.email}</InputError>}
              {!!values.email && values.email.trim() === emailInUse && (
                <>
                  <InputError>This email address is already in use!</InputError>
                  <InputErrorExtra>
                    You could try <Link to={`/login/${values.email.trim()}/`}>logging in</Link>
                  </InputErrorExtra>
                </>
              )}
              {!!values.email && values.email.trim() === existingInvitationEmail && (
                <>
                  <InputError>An invite has already been sent to this address!</InputError>
                  <InputErrorExtra>
                    Please check your email inbox for an invitation link to get started with
                    Notebird.
                  </InputErrorExtra>
                </>
              )}
              <Spacer height='24px' />

              {/* Password */}
              <FormLabel htmlFor='password'>Set password</FormLabel>
              <PasswordInput
                forwardedRef={passwordRef}
                placeholder='8 characters minimum'
                autoComplete='new-password'
                disabled={isSubmitting}
                {...getFieldProps({ name: 'password' })}
                error={!!submitCount && !!errors.password}
                maxLength={300}
              />
              {!!submitCount && !!errors.password && <InputError>{errors.password}</InputError>}
              <Spacer height='32px' />

              {/* Submit */}
              <PrimaryButton block type='submit' disabled={submitDisabled}>
                Get Started
              </PrimaryButton>

              {/* Agreement */}
              <Agreement>
                By clicking {"'Get Started'"} you agree to Notebird&apos;s
                <br />
                <a target='_blank' rel='noopener noreferrer' href='https://notebird.app/privacy'>
                  Privacy Policy
                </a>{' '}
                &amp;{' '}
                <a target='_blank' rel='noopener noreferrer' href='https://notebird.app/terms'>
                  Terms of Service
                </a>
              </Agreement>
            </form>
          </Padding>
        </SignUpPanel>

        {/* Log in notice footer */}
        <Footer>
          Already have an account?
          <br />
          <Link to={`/login/${values.email.trim()}/`}>Log In</Link>
        </Footer>

        <Spacer height='64px' />
      </PageWrapper>
    </>
  );
}
