import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

// Session fetch helper
export function getSessionValue<S>(id: string | null): S | null {
  try {
    return id ? JSON.parse(sessionStorage.getItem(id) as string) : null;
  } catch {
    return null;
  }
}

// Hook
function useSessionState<S>(
  defaultState: S,
  id: string | null
): [S, Dispatch<SetStateAction<S>>, () => void] {
  // Use session value or default
  const sessionValue = getSessionValue(id) !== null ? (getSessionValue(id) as S) : defaultState;
  // Set initial state to session value if present (defaultState if not)
  const [sessionState, setSessionState] = useState<S>(sessionValue);

  // If ID ever changes, reset sessionState accordingly
  useEffect(() => {
    setSessionState(sessionValue);
    // eslint-disable-next-line
  }, [id]);

  // Update session storage any time state (or id) changes
  // (but do nothing if id isn't defined)
  useEffect(() => {
    if (id) {
      try {
        sessionStorage.setItem(id, JSON.stringify(sessionState));
      } catch {
        console.error(
          'Could not stringify value to pass into sessionStorage. Setting to null.',
          'ID: ' + id
        );
        sessionStorage.setItem(id, JSON.stringify(null));
      }
    }
  }, [sessionState, id]);

  // Establish a way to unset session
  const unsetSessionState = useCallback(() => {
    id && sessionStorage.removeItem(id);
  }, [id]);

  // Return state
  return [sessionState, setSessionState, unsetSessionState];
}

export default useSessionState;
