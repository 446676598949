// POTENTIAL REFACTOR: Similar to `FormikPhoneInput`
import { Fragment } from 'react';

import Person, { EMAIL_TYPES } from '@/classes/Person';

import { FieldArray, useField, useFormikContext } from 'formik';

import RemoveButton from './RemoveButton';

import FormikInput from '@/components/formElements/FormikInput';
import FormikSelect from '@/components/formElements/FormikSelect';

import { SecondaryButton } from '@/components/common/Buttons';
import Expanded from '@/components/common/Expanded';
import FlexRow from '@/components/common/FlexRow';
import Spacer from '@/components/common/Spacer';

// Defaults
const MAX_EMAILS = 10;
// Select options
const options = EMAIL_TYPES.map(type => ({
  value: type,
  label: type,
}));

// Component
const FormikEmailInputs = () => {
  const { isSubmitting } = useFormikContext();
  const [{ value: emails }] = useField<Person['emails']>('emails');
  return (
    <FieldArray
      name='emails'
      render={arrayHelpers => (
        <>
          {emails.map((_email, index) => (
            <Fragment key={index}>
              <FlexRow align='start'>
                <FormikSelect
                  name={`emails.${index}.type`}
                  options={options}
                  isSearchable={false}
                />
                <Expanded>
                  <FormikInput
                    name={`emails.${index}.address`}
                    autoFocus={!emails[index].address}
                    placeholder='Enter email address'
                    maxLength={300}
                    data-intercom-target='Person email address field'
                    autoComplete='off'
                  />
                </Expanded>
                <RemoveButton disabled={isSubmitting} onClick={() => arrayHelpers.remove(index)} />
              </FlexRow>
              {/* {meta.touched && !!meta.error ? <Error>{meta.error}</Error> : null} */}
              <Spacer height='8px' />
            </Fragment>
          ))}
          <SecondaryButton
            disabled={
              !!emails.find(({ address }) => !address.trim()) || emails.length === MAX_EMAILS
            }
            leadingIcon='add_circle'
            onClick={() =>
              arrayHelpers.push({
                type:
                  EMAIL_TYPES.find(type => !emails.map(email => email.type).includes(type)) ||
                  'Other',
                address: '',
              })
            }
            data-intercom-target='Person add email button'
          >
            Add <strong>email</strong>
          </SecondaryButton>
        </>
      )}
    />
  );
};
export default FormikEmailInputs;
