import styled from 'styled-components';

const TransitionWrapper = styled.div<{ offset: number }>`
  & > * {
    transform-origin: top left;
  }

  /* Enter */
  .enter {
    opacity: 0;
    &.task-item {
      transform: scale(0.6, 0.3);
    }
  }
  .enter-active {
    opacity: 1;
    transition:
      opacity 400ms 100ms,
      transform 400ms ${({ theme }) => theme.easeStandard} 100ms;
    &.task-item {
      transform: scale(1);
    }
  }
  /* This little '+' adjacent trick is to select all the elements after the LAST */
  /* transitioning element: https://stackoverflow.com/a/33859457 */
  .enter + *:not(.enter),
  .enter + *:not(.enter) ~ * {
    transform: translateY(-${({ offset }) => offset}px);
  }
  .enter-active + *:not(.enter-active),
  .enter-active + *:not(.enter-active) ~ * {
    transition: transform 400ms ${({ theme }) => theme.easeStandard};
    transform: translateY(0);
  }

  /* Exit */
  .exit-active {
    opacity: 0.01;
    transition:
      opacity 300ms,
      transform 300ms ${({ theme }) => theme.easeStandard};
    &.task-item {
      transform: scale(0.6, 0.3);
    }
  }
  .exit + *:not(.exit),
  .exit + *:not(.exit) ~ * {
    transform: translateY(0);
  }
  .exit-active + *:not(.exit-active),
  .exit-active + *:not(.exit-active) ~ * {
    transition: transform 300ms ${({ theme }) => theme.easeStandard} 100ms;
    transform: translateY(-${({ offset }) => offset}px);
  }
`;
export default TransitionWrapper;
