import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import 'flatpickr/dist/themes/airbnb.css';

import isFuzzyTextMatch from '@/lib/helpers/isFuzzyTextMatch';

import flatpickr from 'flatpickr';
import { useField, useFormikContext } from 'formik';
import { DateTime } from 'luxon';

import FormError from '@/components/formElements/styled/FormError';
import FormInput from '@/components/formElements/styled/FormInput';
import FormLabel from '@/components/formElements/styled/FormLabel';

import { SecondaryButton } from '@/components/common/Buttons';
import Icon from '@/components/common/Icon';

// Styles
const InputWrapper = styled.div<{ hasError?: boolean }>`
  position: relative;

  /* Copied ( with just a couple slight tweaks ) from <FormInput/> */
  /* To style iOS/mobile picker properly */
  input.flatpickr-mobile {
    z-index: 9;
    appearance: none;
    width: 100%;
    padding: 12px 0 12px 12px;
    min-height: 46px;

    /* For inputs only ( not text areas ) */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    border: 2px solid ${({ hasError, theme }) => (hasError ? theme.danger600 : theme.lightAccent)};
    border-radius: 4px;
    transition: border-color 100ms ease-out;

    font-size: 16px;
    font-weight: bold;
    color: ${({ theme }) => theme.textPrimary};

    background-color: ${({ theme }) => theme.sheetBackgroundColor};

    &:hover {
      border: 2px solid
        ${({ hasError, theme }) => (hasError ? theme.danger400 : theme.textTertiary)};
    }
    &:focus {
      outline: none;
      border: 2px solid ${({ hasError, theme }) => (hasError ? theme.danger400 : theme.primary500)};
      box-shadow: ${props => props.theme.shadow300};
    }
    &::placeholder {
      font-weight: normal;
      color: ${({ theme }) => theme.textFaded};
      letter-spacing: 0.025em;
    }
    &:disabled {
      cursor: not-allowed;
      color: ${({ theme }) => theme.textTertiary};
      background-color: ${({ theme }) => theme.hoverFade};
      opacity: 0.5;
    }
  }

  /* To move from center to left on mobile safari */
  input::-webkit-date-and-time-value {
    text-align: left;
  }
`;
const ActionButton = styled(SecondaryButton)`
  z-index: 10;
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  right: 2px;
  top: 2px;
  height: calc(100% - 4px);
  padding: 8px;
  i {
    margin: 0 4px;
    width: 16px;
    height: 16px;
    font-size: 16px;
    font-weight: bold;
    color: ${props => props.theme.grey400};
  }
`;
const InputIconWrapper = styled.div`
  pointer-events: none;
  z-index: 10;
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  right: 2px;
  top: 2px;
  height: calc(100% - 4px);
  padding: 8px;
  i {
    margin: 0 4px;
    width: 16px;
    height: 16px;
    font-size: 16px;
    font-weight: bold;
    color: ${props => props.theme.grey400};
  }
`;

// Props
interface Props extends flatpickr.Options.Options {
  label?: React.ReactNode;
  labelIcon?: string;
  optional?: boolean;
  name: string;
  disabled?: boolean;
  autoFocus?: boolean;
  isClearable?: boolean;
  intercomTarget?: string;
  enableTime?: boolean;
}

// Component
const FormikDatePicker = ({
  label,
  labelIcon,
  optional,
  name,
  disabled,
  autoFocus,
  isClearable = true,
  intercomTarget = 'Date picker',
  enableTime = false,
  ...props
}: Props) => {
  const { isSubmitting, setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const defaultDate = useRef(
    field.value
      ? enableTime
        ? // Date AND Time ( Have to round to nearest minute to prevent weird validation errors on mobile )
          DateTime.fromISO(field.value).startOf('minute').toJSDate()
        : // Date only
          DateTime.fromISO(field.value).set({ hour: 12 }).toJSDate()
      : undefined
  );

  // Where to store input and formpickr references
  const inputRef = useRef<HTMLInputElement>(null);
  const flatpickrRef = useRef<flatpickr.Instance>();

  // Track input value and focus
  const [inputValue, setInputValue] = useState('');

  // Load flatpickr on mount
  // When date only, set hour to 12 as alternate fix for safari localeString timezone bug:
  // https://github.com/moment/luxon/issues/392
  useEffect(() => {
    // Keep input and formik values up to date ( on picker change and close )
    function handleChangeClose(dates: Date[], dateString: string) {
      setInputValue(dateString);

      // Date AND time
      if (enableTime) {
        setFieldValue(name as never, DateTime.fromJSDate(dates[0]).toISO());
      } // Date only
      else {
        setFieldValue(name as never, DateTime.fromJSDate(dates[0]).set({ hour: 12 }).toISODate());
      }

      // When no valid value present on change...
      if (dates.length === 0) {
        // If clearing isn't allowed, we just set to default ( or now if no default )
        if (!isClearable) flatpickrRef.current?.setDate(defaultDate.current ?? new Date(), true);
        // On mobile, if dates array is empty, it means user has picked datetime outside bounds
        // To prevent clearing of input, we just set to default ( or maxDate if no default )
        else if (props.maxDate)
          flatpickrRef.current?.setDate(defaultDate.current ?? props.maxDate, true);
      }
    }
    if (inputRef.current) {
      flatpickrRef.current = flatpickr(inputRef.current, {
        ...props,
        allowInput: true,
        enableTime,
        // How to show date value in input field
        // ( things get weird on mobile, hence the funny `format` ordeal )
        // ( empty string format means we're NOT on mobile )
        // https://github.com/flatpickr/flatpickr/issues/1508
        dateFormat: '',
        formatDate: (date: Date, format) => {
          // Mobile
          if (format) return flatpickr.formatDate(date, format);

          // Non-mobile

          // Date AND time
          const dateTime = DateTime.fromJSDate(date);
          if (enableTime) return dateTime.toLocaleString(DateTime.DATETIME_MED);

          // Date only
          if (dateTime.hasSame(DateTime.local(), 'day')) return 'Today';
          if (dateTime.hasSame(DateTime.local().plus({ days: 1 }), 'day')) return 'Tomorrow';
          if (dateTime.hasSame(DateTime.local().minus({ days: 1 }), 'day')) return 'Yesterday';

          return dateTime.set({ hour: 12 }).toLocaleString(DateTime.DATE_MED);
        },
        // How to take input value and make a date
        parseDate: (dateString, format) => {
          if (isFuzzyTextMatch(dateString, 'today')) return new Date();
          if (isFuzzyTextMatch(dateString, 'tomorrow'))
            return DateTime.local().plus({ days: 1 }).toJSDate();
          if (isFuzzyTextMatch(dateString, 'yesterday'))
            return DateTime.local().minus({ days: 1 }).toJSDate();

          return (
            (format && flatpickr.parseDate(dateString, format)) || new Date(Date.parse(dateString))
          );
        },
        // Make sure input also starts with default date ( if any )
        defaultDate: defaultDate.current,
        onReady: (_dates, dateString) => {
          setInputValue(dateString);
        },
        onChange: handleChangeClose,
        onClose: handleChangeClose,
      });
    }

    // Unload on unmount
    return () => flatpickrRef.current?.destroy();

    // Only needs to run on load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormLabel
      label={label}
      labelIcon={labelIcon}
      htmlFor={name}
      optional={optional}
      data-intercom-target={intercomTarget}
    >
      {/* Input wrapper */}
      <InputWrapper hasError={meta.touched && !!meta.error}>
        <FormInput
          ref={inputRef}
          id={name}
          name={name}
          type='text'
          disabled={disabled || isSubmitting}
          hasError={meta.touched && !!meta.error}
          onChange={event => {
            setInputValue(event.target.value);
            const date = new Date(event.target.value);
            !isNaN(date.getTime())
              ? flatpickrRef.current?.setDate(date)
              : flatpickrRef.current?.clear(false);
          }}
          // Force value to our own string ( don't update when flatpickr says )
          value={inputValue}
          // Select all text when focusing
          onFocus={() => inputRef.current?.setSelectionRange(0, inputRef.current.value.length)}
          onBlur={event => field.onBlur(event)}
          // Close picker when pressing enter
          onKeyDown={({ key }) => key === 'Enter' && flatpickrRef.current?.close()}
          autoFocus={autoFocus}
        />

        {/* Close/clear button ( if has any input value ) */}
        {(!!inputValue.trim() || !!field.value) && isClearable && (
          <ActionButton
            dull
            leadingIcon='close'
            onClick={e => {
              e.preventDefault(); // Don't open/focus when clearing
              flatpickrRef.current?.clear();
              flatpickrRef.current?.close();
            }}
          />
        )}

        {/* Calendar Icon when not clearable + has value ) */}
        {(!isClearable || (!inputValue.trim() && !field.value)) && (
          <InputIconWrapper>
            <Icon icon='today' />
          </InputIconWrapper>
        )}
      </InputWrapper>

      {/* Errors ( if any and has touched ) */}
      {meta.touched && !!meta.error ? <FormError>{meta.error}</FormError> : null}
    </FormLabel>
  );
};
export default FormikDatePicker;
