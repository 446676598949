import styled from 'styled-components';

import isFuzzyTextMatch from '@/lib/helpers/isFuzzyTextMatch';

import useAppState, { useSetAppState } from '@/contexts/appState';
import useOrganization from '@/contexts/organization';
import useUser from '@/contexts/user';

import Avatar from '@/components/common/Avatar';
import Icon from '@/components/common/Icon';
import NotebirbAvatar from '@/components/common/NotebirbAvatar';

// Styles
const DropdownAvatarWrapper = styled.div`
  position: relative;
  transition: transform 120ms ${({ theme }) => theme.easeStandard} 100ms;
`;
const DropdownIcon = styled(Icon).attrs({
  children: 'keyboard_arrow_down',
  iconSize: '36px',
})`
  position: absolute;
  right: 16px;
  @media (max-width: 424px) {
    right: 9px;
  }
  color: ${({ theme }) => theme.linkColor};
  transition: transform 200ms ${({ theme }) => theme.easeStandard};
  transform: scale(0);
`;
const StyledDropdownToggle = styled.div<{ isOpen: boolean }>`
  position: relative;
  flex: 1;
  /* Don't stretch on mobile */
  @media (max-width: 1023px) {
    flex: none;
  }

  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 48px;
  padding-left: 16px;
  @media (max-width: 1023px) {
    padding: 0;
  }

  button {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 16px;
    @media (max-width: 424px) {
      padding: 0 8px;
    }
    @media (max-width: 1023px) {
      border-radius: 6px;
    }

    &:hover,
    &:active,
    &:focus {
      outline: none;
      background-color: ${props => props.theme.hoverFade};
      ${DropdownAvatarWrapper} {
        transform: scale(0);
      }
      ${DropdownIcon} {
        transform: scale(1) rotate(${({ isOpen }) => (isOpen ? '180deg' : '0')});
      }
    }
  }
`;
const DropdownToggleNameBlock = styled.div`
  text-align: right;
  padding-right: 12px;
  max-width: 20vw;

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* Name */
  color: ${props => props.theme.textTertiary};
  font-size: 14px;

  /* Organizaition ( or name if both are same ) */
  div:last-child {
    font-size: 16px;
    color: ${props => props.theme.textSecondary};
    font-weight: bold;
  }

  /* Hide this block on mobile */
  @media (max-width: 1023px) {
    display: none;
  }
`;

// Component
const DropdownToggle = (props: React.ComponentPropsWithoutRef<'div'>) => {
  const { isDropdownOpen } = useAppState();
  const setAppState = useSetAppState();

  const [user] = useUser();
  const showNotebirb = !!user && (user.profile.preferNotebirb || !user.profile.photo);
  const [organization] = useOrganization();

  return (
    <StyledDropdownToggle isOpen={isDropdownOpen} {...props}>
      {!!user && (
        <button
          type='button'
          onClick={() => setAppState({ isDropdownOpen: !isDropdownOpen })}
          data-intercom-target='Dropdown menu button'
        >
          <DropdownToggleNameBlock>
            <div>{user.profile.name.display}</div>
            {/* Show organization name if different from user name */}
            {!!organization &&
              !isFuzzyTextMatch(user.profile.name.full, organization.profile.name) && (
                <div>{organization.profile.name}</div>
              )}
          </DropdownToggleNameBlock>
          <DropdownIcon />
          {/* Avatar ( or notebirb ) */}
          <DropdownAvatarWrapper>
            {showNotebirb && <NotebirbAvatar notebirb={user.profile.notebirb} size={36} />}
            {!showNotebirb && <Avatar photo={user.profile.photo} size={32} isUser />}
          </DropdownAvatarWrapper>
        </button>
      )}
    </StyledDropdownToggle>
  );
};
export default DropdownToggle;
