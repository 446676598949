import { HTMLProps, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import Expanded from '@/components/common/Expanded';
import Icon from '@/components/common/Icon';
import Spacer from '@/components/common/Spacer';

const LeadingIcon = styled(Icon)`
  margin-right: 8px;
`;
const TrailingIcon = styled(Icon)`
  margin-right: 8px;
`;
const TitleWrapper = styled.div`
  overflow: hidden;
  margin-right: 16px;
`;
const MainTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.textSecondary};

  a i {
    color: ${({ theme }) => theme.linkColor};
    vertical-align: top;
    margin-left: 4px;
    &:hover {
      color: ${({ theme }) => theme.linkHover};
    }
  }
`;
const Subtitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: normal;
  color: ${({ theme }) => theme.textFaded};

  a i {
    color: ${({ theme }) => theme.linkColor};
    vertical-align: middle;
    &:hover {
      color: ${({ theme }) => theme.linkHover};
    }
  }
  button {
    padding: 0;
  }
`;
const ExpandIcon = styled(Icon)<{ active: boolean }>`
  margin-left: 8px;
  color: ${({ theme }) => theme.textFaded};
  border-radius: 50%;

  transform: rotate(${({ active }) => (!active ? '0' : '180deg')});
  transition: transform 300ms ${({ theme }) => theme.easeStandard};
`;

interface SheetHeaderProps extends HTMLProps<HTMLDivElement> {
  expandable?: boolean;
  active?: boolean;
  primary?: boolean;
  sticky?: boolean;
  stickyTop?: string;
  leading?: ReactNode;
  leadingIcon?: string;
  trailing?: ReactNode;
  trailingIcon?: string;
  mainTitle?: ReactNode;
  subtitle?: ReactNode;
}

// Base component
function SheetHeaderBase({
  expandable,
  active = true,
  primary,
  sticky,
  stickyTop,
  leading,
  leadingIcon,
  trailing,
  trailingIcon,
  mainTitle,
  subtitle,
  children,
  ...rest
}: SheetHeaderProps) {
  const titleOrSubtitleExists = !!mainTitle || !!subtitle;
  return (
    <div {...rest}>
      {/* Leading */}
      {leadingIcon && <LeadingIcon icon={leadingIcon} />}
      {!!leading && leading}
      {/* Title */}
      {titleOrSubtitleExists && (
        <TitleWrapper>
          {!!mainTitle && <MainTitle>{mainTitle}</MainTitle>}
          {titleOrSubtitleExists && <Spacer height='2px' />}
          {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
        </TitleWrapper>
      )}
      {/* Main (expanded) */}
      <Expanded>{children}</Expanded>

      {/* Trailing */}
      {!!trailing && trailing}
      {trailingIcon && <TrailingIcon icon={trailingIcon} />}
      {expandable && <ExpandIcon icon='keyboard_arrow_down' iconSize='30px' active={active} />}
    </div>
  );
}

// Exported style component
const SheetHeader = styled(SheetHeaderBase).attrs(({ active = true }) => ({ active }))`
  z-index: ${({ primary }) => (primary ? 12 : 10)};
  flex-shrink: 0;
  position: ${({ sticky }) => (sticky ? 'sticky' : 'relative')};
  top: ${({ sticky, stickyTop }) => (sticky ? (stickyTop ? stickyTop : '0') : 'auto')};

  background-color: ${({ theme }) => theme.sheetBackgroundColor};
  transition: background-color 200ms ease-out; /* For theme toggle */
  box-shadow: ${({ theme }) => theme.headerShadow};

  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  @media (max-width: 1023px) {
    padding: 0 16px;
  }

  ${LeadingIcon} {
    color: ${({ active, theme }) => (active ? theme.linkColor : theme.textTertiary)};
  }
  ${MainTitle} {
    color: ${({ active, theme }) => (active ? theme.textSecondary : theme.textTertiary)};
  }

  /* For accordion headers */
  ${({ expandable, active, theme }) =>
    expandable &&
    css`
      cursor: pointer;

      /* Hover */
      &:hover {
        box-shadow: ${theme.headerShadowHover};

        ${LeadingIcon} {
          color: ${active ? theme.linkHover : theme.linkColor};
        }
        ${MainTitle}, ${ExpandIcon} {
          color: ${theme.textSecondary};
        }
        ${ExpandIcon} {
          background-color: ${theme.hoverFade};
        }
      }
    `}
`;
export default SheetHeader;
