import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import firebase, { db } from '@/lib/firebase';

import { generateUpdatedByDottedMeta } from '@/lib/helpers/generateMeta';

import { useField, useFormikContext } from 'formik';
import sortBy from 'lodash/sortBy';

import useAppState from '@/contexts/appState';
import useLocation from '@/contexts/location';
import useUser from '@/contexts/user';

import FormikCheckbox from '@/components/formElements/FormikCheckbox';
import CreatableSelect from '@/components/formElements/styled/FormCreatableSelect';
import Error from '@/components/formElements/styled/FormError';
import Label from '@/components/formElements/styled/FormLabel';

import { SecondaryButton } from '@/components/common/Buttons';
import Expanded from '@/components/common/Expanded';

// Styles
const FlexRow = styled.div`
  display: flex;
  align-items: center;
  button:hover {
    color: ${({ theme }) => theme.danger500};
  }
`;

// Component props
interface Props {
  label?: React.ReactNode;
  labelIcon?: string;
  optional?: boolean;
  name?: string;
  disabled?: boolean;
  intercomTarget?: string;
  showSaved?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  savePlaceName?: string;
}
// Component
// ( this draws heavily from name/person selector in `RelationshipForm` )
const FormikTaskNotes = ({
  label,
  labelIcon,
  optional,
  name = 'notes',
  disabled,
  showSaved = true,
  intercomTarget = 'Task notes field',
  ...rest
}: Props) => {
  const { isSubmitting, setFieldValue } = useFormikContext();
  const [{ value: selectedTask }, meta] = useField<string | null>(name);

  // App state
  const { isLocationAdmin, isSingleUser } = useAppState();
  // Context data
  const [user] = useUser();
  const [location] = useLocation();

  // Value for input
  const [inputValue, setInputValue] = useState('');

  // Get saved/favorite tasks for this location and format
  const savedTasksItems = useMemo(
    () =>
      showSaved
        ? sortBy(
            location?.savedTasks?.map((task, index) => ({
              label: (
                <FlexRow key={task + index}>
                  <Expanded>{task}</Expanded>
                  {isLocationAdmin && task.trim() !== selectedTask && (
                    <SecondaryButton
                      small
                      dull
                      onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        user &&
                          db.doc(location.docPath).update({
                            ...generateUpdatedByDottedMeta(user),
                            savedTasks: firebase.firestore.FieldValue.arrayRemove(task),
                          });
                      }}
                    >
                      Remove as preset
                    </SecondaryButton>
                  )}
                </FlexRow>
              ),
              value: task,
            })) ?? [],
            'value'
          )
        : [],
    [isLocationAdmin, location, selectedTask, showSaved, user]
  );

  // Track focus/blur
  const isSelectingTask = useRef(false);
  const hasFocus = useRef(false);
  // If input is ever cleared all the way out ( while focused ),
  // set selected value to empty too
  useEffect(() => {
    hasFocus.current && !inputValue.trim() && selectedTask && setFieldValue(name as never, null);
  }, [inputValue, name, selectedTask, setFieldValue]);
  return (
    <>
      <Label
        label={label}
        labelIcon={labelIcon}
        htmlFor={name}
        optional={optional}
        data-intercom-target={intercomTarget}
      >
        <CreatableSelect
          inputId={name}
          isDisabled={disabled || isSubmitting}
          // Blur so search value can be reset on focus
          blurInputOnSelect
          // Allow creation of new task as long as there's something to create and doesn't match existing saved task
          isValidNewOption={(searchInput: string) =>
            !!searchInput.trim() &&
            !location?.savedTasks
              ?.map(task => task.trim().toLowerCase())
              .includes(searchInput.trim().toLowerCase())
          }
          formatCreateLabel={(searchInput: string) => (
            <div>
              Use &quot;<strong>{searchInput}</strong>&quot;
            </div>
          )}
          // These are the options taken from location prefs
          options={savedTasksItems}
          // This is the value when searching
          inputValue={inputValue}
          // Every time on type, set input value
          onInputChange={(val: string) => setInputValue(val)}
          // This is the currently selected value
          value={selectedTask?.trim() ? { label: <>{selectedTask}</>, value: selectedTask } : null}
          // Whenever a task is selected, set it as the current value
          onChange={selection => {
            if (selection) {
              // This to track whether selecting or not to
              // prevent onBlur from being too aggressive
              isSelectingTask.current = true;
              setFieldValue(name as never, selection.value.trim() || null);
            }
          }}
          // When focus, we want to return the current value to the search field
          onFocus={() => {
            hasFocus.current = true;
            // This is also when we should reset the isSelecting value
            isSelectingTask.current = false;
            setInputValue(selectedTask || '');
          }}
          // When blurring, change value to match input IF
          // this blur isn't just triggered by 'onChange' and the input
          // is in fact different than the current value
          onBlur={event => {
            hasFocus.current = false;
            const inputValue = event.target.value.trim();
            if (!isSelectingTask.current && inputValue !== selectedTask) {
              const match = location?.savedTasks?.find(
                task => task.trim().toLowerCase() === inputValue.trim().toLowerCase()
              );
              setFieldValue(name as never, match || inputValue.trim() || null);
            }
          }}
          {...rest}
        />
        {meta.touched && !!meta.error ? <Error>{meta.error}</Error> : null}
      </Label>
      {isLocationAdmin &&
        !!selectedTask &&
        !location?.savedTasks
          ?.map(task => task.trim().toLowerCase())
          .includes(selectedTask.trim().toLowerCase()) && (
          <FormikCheckbox
            disabled={disabled}
            name='saveTask'
            data-intercom-target={intercomTarget + ' - save'}
          >
            <strong>Save as preset</strong>
            {!isSingleUser && ' for all users'}
          </FormikCheckbox>
        )}
    </>
  );
};

export default FormikTaskNotes;
