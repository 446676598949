import { DateTime } from 'luxon';

import AdminImportSpreadsheet from './AdminImportSpreadsheet';
import AdminSupport from './AdminSupport';
import Meta from './Meta';
import Place from './Place';
import { StripeStatus } from './Stripe';
import { UserProfile } from './User';

export const ORGANIZATION_TYPES = [
  'Ministry',
  'StudentMinistry',
  'Synagogue',
  'School',
  'Nonprofit',
  'Company',
  'Personal',
  'Other',
] as const;
export type OrganizationType = (typeof ORGANIZATION_TYPES)[number];

export const ORGANIZATION_EMOJI: {
  [key in OrganizationType]: { string: string; symbol: string };
} = {
  Ministry: { string: 'latin_cross', symbol: '✝️' },
  StudentMinistry: { string: 'books', symbol: '📚' },
  Synagogue: { string: 'synagogue', symbol: '🕍' },
  School: { string: 'children_crossing', symbol: '🚸' },
  Nonprofit: { string: 'earth_americas', symbol: '🌎' },
  Company: { string: 'office', symbol: '🏢' },
  Personal: { string: 'sunglasses', symbol: '😎' },
  Other: { string: 'sparkles', symbol: '✨' },
};

export const INTEGRATION_SERVICES = [
  'planningCenter',
  'breeze',
  'churchWindows',
  'shulCloud',
] as const;
export type IntegrationService = (typeof INTEGRATION_SERVICES)[number];
export type PlanningCenterIntegration = {
  service: 'planningCenter';
  id: string | null;
  userId: string | null;
  status: 'pending' | 'active';
  campusMode: 'single' | 'multi' | null;
  campusNames: string[];
};
export type BreezeIntegration = {
  service: 'breeze';
  id: string | null;
  subdomain: string | null;
  apiLast4: string | null;
  status: 'pending' | 'active' | 'syncing';
  lastExportedAt: firebase.firestore.Timestamp | null;
  lastExportCounts: {
    added: number;
    updated: number;
    preserved: number;
    removed: number;
    archived: number;
    total: number;
  };
};
export type ChurchWindowsIntegration = {
  service: 'churchWindows';
  id: string;
  status: 'pending' | 'active' | 'syncing';
  initialExportAt: firebase.firestore.Timestamp | null;
  lastExportedAt: firebase.firestore.Timestamp | null;
  lastExportCounts: {
    added: number;
    updated: number;
    preserved: number;
    removed: number;
    archived: number;
    total: number;
  };
};
export type ShulCloudIntegration = {
  service: 'shulCloud';
  status: 'pending' | 'active';
  url: string;
  lastExportedAt: firebase.firestore.Timestamp | null;
  lastExportCounts: {
    added: number;
    updated: number;
    preserved: number;
    removed: number;
    archived: number;
    total: number;
  };
};

export default class Organization {
  meta = { ...new Meta() };
  // Core data
  _intercomId: string | null = null;
  _stripe = {
    /** Stripe ID tied to Stripe Account */
    customerId: null as string | null,
    /** Currently active Stripe Subscription */
    subscriptionId: null as string | null,
    /** Price IDs are the specific price/plan in Stripe like monthly/yearly */
    priceId: null as string | null,
    /** Number of users that customer has paid for. */
    quantity: 1,
    /** Number of users that customers MUST pay for. ( optional )
     * This is usually good for when a coupon is applied to get to a particular amount for
     * a particular number of users */
    minQuantity: null as number | null,
    /** Friendly name of coupon labeled in Stripe ( if not `Custom Discount` ) */
    couponName: null as string | null,
    /** Total coupon value off in cents. Applies to the current interval.
     * For example, if interval is month, then the value is the monthly discount. */
    couponAmountOff: null as number | null,
    /** Percent off the total subscription ( in whole percentage number ) */
    couponPercentOff: null as number | null,
    /** Interval of the subscription */
    interval: 'month' as 'month' | 'year',
    /** Interval that the user is compelled to use during conversion
     * This is normally used in conjunction with a custom deal/coupon */
    forceInterval: null as 'month' | 'year' | null,
    /** When the trial or subscription is set to end */
    currentPeriodEnd: DateTime.local()
      .plus({ days: 1 })
      .toJSDate() as unknown as firebase.firestore.Timestamp,
    /** Last 4 digits of the currently active card */
    cardLast4: null as string | null,
    /** Expiry date of the currently active card */
    cardExpMonth: null as number | null,
    /** Expiry year of the currently active card */
    cardExpYear: null as number | null,
    /** Stripe/subscription status */
    status: 'trialing' as StripeStatus,
    /** Indication whether subscription will cancel at plan end */
    cancelAtPeriodEnd: true,
  };
  _integration:
    | PlanningCenterIntegration
    | BreezeIntegration
    | ChurchWindowsIntegration
    | ShulCloudIntegration
    | null = null;
  // Users
  owner = {
    id: '',
    profile: { ...new UserProfile() },
  };
  admins: string[] = [];
  users: string[] = [];
  restrictedAccessUsers: string[] = [];
  archivedUsers: string[] = [];
  // Info / prefs
  profile = {
    name: '',
    type: 'Other' as OrganizationType,
    size: null as string | null, // Could be undefined for orgs < v1.26.1, but otherwise linked with cloud func
    locationCount: null as 'Single' | 'Few' | 'Many' | null,
    website: '',
    phone: '',
    address: null as Place | null,
    isOnboarding: true,
    cms: '', // Could be empty string for orgs < v1.5.0, but otherwise filled during onboarding
    setupMeetingStatus: 'prompt' as 'prompt' | 'dismissed' | 'scheduled' | 'completed',
    referredBy: '', // Could be empty string for orgs < v1.9.2, but otherwise filled during onboarding
    birdcallPlaceId: null as string | null, // Could be undefined for orgs < v1.10.8, but otherwise linked with cloud func
  };
  // These are overridden during onboarding
  preferences = {
    updateTypes: [] as string[],
    milestoneTypes: [] as string[],
    pulseIndicator: {
      enabled: false,
      first: 15,
      second: 30,
    },
    joinDateIndicator: {
      enabled: true,
      first: 30,
      second: 90,
    },
  };
}

// Organization as indexed and returned from Typesense
// ( only with flattened timestamps when returned )
export interface TypesenseOrganization extends Organization {
  id: string; // ID is always included
  // The below are added in when indexing only for super admins
  _id: string;
  userProfiles: UserProfile[];
  adminNotes: string;
  adminStatus: AdminSupport['status'];
  adminImportStatus: AdminImportSpreadsheet['status'] | null;
}
