import { useMemo } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Task from '@/classes/Task';

import { DateTime } from 'luxon';

import useAppState, { useSetAppState } from '@/contexts/appState';

import useBaseQuery from '@/hooks/useBaseQuery';
import useCollection from '@/hooks/useCollection';

import DropdownToggle from '@/components/layout/DropdownToggle';

import KidcardLogo from '@/components/svg/KidcardLogo';
import NotebirdLogo from '@/components/svg/NotebirdLogo';

// Styles
const PrimaryNavWrapper = styled.div`
  /* Todo: should this be in front of page blocker? */
  z-index: 210;

  /* z-index: 120; */
  position: relative;
  flex-shrink: 0;
  width: 100%;
  width: 100vw;
  user-select: none;

  background-color: ${props => props.theme.sheetBackgroundColor};
  box-shadow: ${props => props.theme.shadow100};
  transition: background-color 200ms ease-out; /* For theme toggle */

  /* Move mobile menu to bottom */
  @media (max-width: 1023px) {
    order: 99;
    box-shadow: ${props => props.theme.shadow200Inverted};
  }
`;
const StyledPrimaryNav = styled.div`
  max-width: 1468px;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: stretch;

  /* Mobile adjustments */
  @media (max-width: 1023px) {
    justify-content: center;
    padding-bottom: calc(env(safe-area-inset-bottom) - 16px);
  }
`;
const NavLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 16px;

  /* Mobile adjustments */
  @media (max-width: 1023px) {
    width: 100%;
    max-width: 512px;
    justify-content: space-evenly;
  }
  @media print {
    display: none;
  }
`;
const StyledNavLink = styled(NavLink)<{ disabled?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 24px 16px;

  color: ${props => props.theme.textTertiary};
  font-size: 18px;
  text-decoration: none;

  /* Mobile adjustments */
  @media (max-width: 1023px) {
    padding: 16px 24px;
  }
  @media (max-width: 424px) {
    flex: 1;
    display: block;
    padding: 16px 0;
    font-size: 17px;
    text-align: center;
  }

  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;

    color: ${props => props.theme.textSecondary};
    background-color: ${props => props.theme.hoverFade};
  }

  &.active {
    color: ${props => props.theme.linkColor};
    font-weight: bold;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: ${props => props.theme.linkColor};
      /* Put active indicator above on mobile */
      @media (max-width: 1023px) {
        bottom: auto;
        top: 0;
        height: 5px;
      }
      @media (max-width: 424px) {
        height: 6px;
      }
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      /* pointer-events: none; */
      cursor: not-allowed;
      opacity: 0.5;
    `}
`;
const LogoWrapper = styled.div`
  flex: 1;
  padding: 11px 16px;
  height: 48px;

  @media print {
    display: none;
  }
`;
const TaskBadge = styled.div<{ danger?: boolean }>`
  position: absolute;
  top: 6px;
  left: calc(100% - 20px);
  background-color: ${({ danger, theme }) => (danger ? theme.danger100 : theme.primary100)};
  color: ${({ danger, theme }) => (danger ? theme.danger600 : theme.primary600)};
  font-weight: bold;
  font-size: 14px;
  padding: 1px 5px;
  border-radius: 100%;
  @media (max-width: 1023px) {
    top: 8px;
  }
`;

// Task Nav item (w/ badge)
const TaskNavItem = () => {
  const { uid } = useAppState();
  // Fetch this person's task due today or earlier, so we can count them
  const baseQuery = useBaseQuery({ collection: 'tasks', limit: 99, showArchived: true });
  const todayIso = DateTime.local().toISODate() as string;
  const [tasks] = useCollection<Task>(
    baseQuery
      ?.where('assignedTo.id', '==', uid)
      .where('isCompleted', '==', false)
      .where('dueDate', '<=', todayIso)
      .orderBy('dueDate', 'desc'),
    { trace: 'fetch_badge_tasks' }
  );
  const danger = useMemo(
    () => !!tasks?.find(({ dueDate }) => dueDate && dueDate < todayIso),
    [tasks, todayIso]
  );

  return (
    <StyledNavLink to='/tasks' data-intercom-target='Tasks page nav link'>
      Tasks
      {!!tasks?.length && <TaskBadge danger={danger}>{tasks.length}</TaskBadge>}
    </StyledNavLink>
  );
};

// Component
const PrimaryNav = () => {
  const { kidcardMode } = useAppState();
  const setAppState = useSetAppState();
  return (
    <PrimaryNavWrapper>
      <StyledPrimaryNav>
        {/* Logo */}
        <LogoWrapper
          className='hidden-mobile'
          onClick={() => setAppState({ isDropdownOpen: false })}
        >
          <Link to='/people'>
            {kidcardMode ? (
              <KidcardLogo height='32' tint />
            ) : (
              <NotebirdLogo height='24' tint prefix='primary-nav-' />
            )}
          </Link>
        </LogoWrapper>
        {/* Primary Nav Links */}
        <NavLinkWrapper onClick={() => setAppState({ isDropdownOpen: false })}>
          <StyledNavLink to='/people' data-intercom-target='People page nav link'>
            {kidcardMode ? 'Students' : 'People'}
          </StyledNavLink>
          <StyledNavLink to='/activity' data-intercom-target='Activity page nav link'>
            Activity
          </StyledNavLink>
          {/* Task Nav Item ( w/ badge ) */}
          <TaskNavItem />
        </NavLinkWrapper>
        {/* Dropdown Toggle */}
        <DropdownToggle className='hidden-mobile' />
      </StyledPrimaryNav>
    </PrimaryNavWrapper>
  );
};
export default PrimaryNav;
