import styled from 'styled-components';

import { useSetAppState } from '@/contexts/appState';

import DropdownToggle from '@/components/layout/DropdownToggle';

import { SecondaryButton } from '@/components/common/Buttons';
import Expanded from '@/components/common/Expanded';
import Spacer from '@/components/common/Spacer';

// Button group helper component
export const MobileButtonGroup = styled.div`
  font-size: 16px;
  display: inline-block;
  margin: 0 auto;

  a,
  button {
    padding: 8px 12px;
    border: 2px solid ${({ theme }) => theme.primary500};
    display: inline-block;
    font-weight: normal;
    color: ${({ theme }) => theme.textTertiary};
  }
  a.active,
  button.active {
    background-color: ${({ theme }) => theme.primary500};
    color: white;
  }

  a:hover,
  a:active,
  a:focus,
  button:hover,
  button:active,
  button:focus {
    border-color: ${({ theme }) => theme.primary600};
    text-decoration: none;
    color: ${({ theme }) => theme.textSecondary};
  }

  a.active:hover,
  a.active:active,
  a.active:focus,
  button.active:hover,
  button.active:active,
  button.active:focus {
    border-color: ${({ theme }) => theme.primary500};
    background-color: ${({ theme }) => theme.primary600};
    color: white;
  }

  a:first-child,
  button:first-child {
    border-radius: 6px 0 0 6px;
    border-right: none;
  }
  a:last-child,
  button:last-child {
    border-radius: 0 6px 6px 0;
    border-left: none;
  }
`;

// Styles
const StyledMobilePageHeader = styled.div`
  /* Todo: should this be in front of page blocker? ( like primary nav ) */
  z-index: 210;
  /* z-index: 10; */
  flex-shrink: 0;
  width: 100%;

  background-color: ${({ theme }) => theme.sheetBackgroundColor};
  transition: background-color 200ms ease-out; /* For theme toggle */
  box-shadow: ${({ theme }) => theme.headerShadow};

  min-height: 64px;
  display: flex;
  align-items: center;

  @media (min-width: 1024px) {
    display: none;
  }
`;
const GroupsAsideToggle = styled(SecondaryButton).attrs(() => ({ leadingIcon: 'sort' }))`
  padding: 10px 12px;
  @media (max-width: 424px) {
    padding: 10px 8px;
  }
  i {
    font-size: 28px;
    width: 28px;
    height: 28px;
  }
`;
const ExpandedChildren = styled(Expanded)<{ centered: boolean }>`
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.textSecondary};
`;

// Component
interface Props {
  children?: React.ReactNode;
  hideGroupsAsideToggle?: boolean;
}
function MobilePageHeader({ children = '', hideGroupsAsideToggle = false }: Props) {
  // Groups aside menu state ( from context )
  const setAppState = useSetAppState();

  return (
    <StyledMobilePageHeader>
      {/* Groups aside toggle */}
      {!hideGroupsAsideToggle ? (
        <GroupsAsideToggle
          onClick={() => {
            setAppState({ isMobileAsideOpen: true, isDropdownOpen: false });
          }}
        />
      ) : (
        <Spacer width='16px' />
      )}
      {/* Main ( expanded and from context ) */}
      <ExpandedChildren
        centered={!hideGroupsAsideToggle}
        onClick={() => {
          setAppState({ isDropdownOpen: false });
        }}
      >
        {children}
      </ExpandedChildren>
      {/* Button to expand menu */}
      <DropdownToggle />
    </StyledMobilePageHeader>
  );
}
export default MobilePageHeader;
