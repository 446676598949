import { createContext, useContext, useMemo } from 'react';

import Doc from '@/classes/Doc';
import Milestone from '@/classes/Milestone';

import { DateTime } from 'luxon';

import useBaseQuery from '@/hooks/useBaseQuery';
import useCollection from '@/hooks/useCollection';

import useAppState from './appState';

interface RelativeMilestone extends Doc<Milestone> {
  dateTime: DateTime;
  annualizedDateTime: DateTime;
  yearsAgo: number;
}

// Contexts ( with hook shortcuts )
const fetchingMilestones: [Doc<RelativeMilestone>[] | null, boolean] = [null, true];
const milestonesContext = createContext(fetchingMilestones);
const useMilestones = () => useContext(milestonesContext);
export default useMilestones;
// Group filtered milestones ( all, followed, or group specific )
const groupFilteredMilestonesContext = createContext<Doc<RelativeMilestone>[]>([]);
export const useGroupFilteredMilestones = () => useContext(groupFilteredMilestonesContext);
// Type filtered milestones ( groupFiltered narrowed by user-selected type )
const typeFilteredMilestonesContext = createContext<Doc<RelativeMilestone>[]>([]);
export const useTypeFilteredMilestones = () => useContext(typeFilteredMilestonesContext);

// Context definition w/ provider
export const MilestonesProvider = ({ children }: { children: React.ReactNode }) => {
  // App state
  const { groupId, contentFilter, followedPeopleIds, filterMilestonesBy, showArchivedPeople } =
    useAppState();

  // Gather milestones from 2 groups... `before now` and `after now`... roughly...
  // ( necessary for when current date is close to beginning or end of year and wrap-around should be considered )
  const baseQuery = useBaseQuery({
    collection: 'milestones',
    excludeMemberGroups: true,
    showArchived: showArchivedPeople,
    limit: 2000,
  })?.orderBy('dateMmddyyyy', 'desc');
  // Today
  const todayDateTime = DateTime.local().startOf('day');
  const today = todayDateTime.toFormat('MMdd') + '0000';
  // Past cutoff ( yesterday )
  const cutoffStartDateTime = todayDateTime.minus({ days: 1 });
  const cutoffStart = cutoffStartDateTime.toFormat('MMdd') + '0000';
  // Future cutoff ( +7 days )
  const cutoffEndDateTime = todayDateTime.plus({ days: 7 });
  const cutoffEnd = cutoffEndDateTime.toFormat('MMdd') + '9999';
  // Queries behave differently cutoffs do not share the same year
  const cutoffsHaveSameYear = cutoffStartDateTime.hasSame(cutoffEndDateTime, 'year');

  // Execute queries ( NOTE: futureMilestones are today inclusive, not past )
  const [pastMilestones, pastMilestonesAreFetching] = useCollection<Milestone>(
    cutoffsHaveSameYear
      ? baseQuery?.where('dateMmddyyyy', '>=', cutoffStart).where('dateMmddyyyy', '<', today)
      : baseQuery?.where('dateMmddyyyy', '>=', cutoffStart),
    { trace: 'fetch_milestones_past' }
  );
  const [futureMilestones, futureMilestonesAreFetching] = useCollection<Milestone>(
    cutoffsHaveSameYear
      ? baseQuery?.where('dateMmddyyyy', '<=', cutoffEnd).where('dateMmddyyyy', '>=', today)
      : baseQuery?.where('dateMmddyyyy', '<=', cutoffEnd),
    { trace: 'fetch_milestones_future' }
  );

  // Assemble both into one stream
  const milestoneDocs = useMemo(
    () => (pastMilestones && futureMilestones ? [...pastMilestones, ...futureMilestones] : null),
    [futureMilestones, pastMilestones]
  );
  // Process milestones relative to current year ( and purge irrelevant )
  const relativeMilestones = useMemo(() => {
    const relativeMilestones: RelativeMilestone[] =
      milestoneDocs?.map(milestone => {
        const dateTime = DateTime.fromISO(milestone.date).startOf('day');
        // Need to figure out if milestone is wrapping around from previous or next year
        let annualizedDateTime = dateTime.set({ year: todayDateTime.year });
        // Milestone isn't near this year, maybe next?
        if (Math.abs(annualizedDateTime.diffNow().as('years')) >= 0.5) {
          annualizedDateTime = annualizedDateTime.plus({ years: 1 });
        }
        // Wasn't near next year, maybe last?
        if (Math.abs(annualizedDateTime.diffNow().as('years')) >= 0.5) {
          annualizedDateTime = annualizedDateTime.minus({ years: 2 });
        }
        return {
          ...milestone,
          dateTime,
          annualizedDateTime,
          yearsAgo: annualizedDateTime.diff(dateTime, 'years').as('years'),
        };
      }) || [];
    // Purge irrelevant milestones
    return relativeMilestones.filter(milestone => {
      // Never show milestones that are years in the future
      if (milestone.yearsAgo < 0) return false;
      // Never show non-annual milestones from this year that aren't originals
      if (milestone.yearsAgo !== 0 && !milestone.showAnnually) return false;
      // Otherwise, we're all good
      return true;
    });
  }, [milestoneDocs, todayDateTime.year]);
  // Value ( including fetching status ) to return
  const milestones = useMemo<typeof fetchingMilestones>(
    () => [relativeMilestones, pastMilestonesAreFetching || futureMilestonesAreFetching],
    [futureMilestonesAreFetching, pastMilestonesAreFetching, relativeMilestones]
  );

  // Filter milestones through followed, groupId or groupFilter
  const groupFilteredMilestones = useMemo(() => {
    if (groupId) {
      return relativeMilestones.filter(milestone => milestone.groups.find(id => id === groupId));
    } else if (contentFilter === 'following') {
      return relativeMilestones.filter(({ person }) => followedPeopleIds.includes(person.id));
    }
    return relativeMilestones;
  }, [contentFilter, followedPeopleIds, groupId, relativeMilestones]);

  // Filter milestones through type ( selector found in aside )
  const typeFilteredMilestones = useMemo(() => {
    if (filterMilestonesBy) {
      return groupFilteredMilestones.filter(({ type }) => type === filterMilestonesBy);
    }
    return groupFilteredMilestones || [];
  }, [filterMilestonesBy, groupFilteredMilestones]);

  return (
    <milestonesContext.Provider value={milestones}>
      <groupFilteredMilestonesContext.Provider value={groupFilteredMilestones}>
        <typeFilteredMilestonesContext.Provider value={typeFilteredMilestones}>
          {children}
        </typeFilteredMilestonesContext.Provider>
      </groupFilteredMilestonesContext.Provider>
    </milestonesContext.Provider>
  );
};
