import { useFormikContext } from 'formik';

import SheetHeader from '@/components/layout/SheetHeader';

import { SecondaryButton } from '@/components/common/Buttons';
import Loader from '@/components/common/Loader';

// Props
interface Props {
  leadingIcon?: string;
  mainTitle?: React.ReactNode;
  subtitle?: React.ReactNode;
  handleCancel?(): void;
}
// Component
const FormikHeader = ({ leadingIcon, mainTitle = '', subtitle, handleCancel }: Props = {}) => {
  const { isSubmitting } = useFormikContext();
  return (
    <>
      <SheetHeader
        primary
        leadingIcon={leadingIcon}
        mainTitle={mainTitle}
        subtitle={subtitle}
        trailing={
          handleCancel ? (
            <SecondaryButton
              trailingIcon='close'
              onClick={() => handleCancel()}
              disabled={isSubmitting}
            />
          ) : undefined
        }
      />
      <Loader show={isSubmitting} />
    </>
  );
};
export default FormikHeader;
