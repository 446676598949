import type { DefaultTheme } from 'styled-components';

import CardinalTheme, { cardinalColors } from './cardinalTheme';
import { darkSemantics, darkShadows } from './darkTheme';

const cardinalThemeDark: DefaultTheme = {
  ...CardinalTheme,
  isDark: true,

  /////////////////////
  // Semantic Colors //
  /////////////////////
  ...darkSemantics,
  linkColor: cardinalColors.primary400,
  linkHover: cardinalColors.primary300,
  primaryDark: cardinalColors.primary200,

  /////////////
  // Shadows //
  /////////////
  ...darkShadows,
};

export default cardinalThemeDark;
