import { useEffect, useState } from 'react';
import styled from 'styled-components';

import useAppState, { useSetAppState } from '@/contexts/appState';

import Scrollable from '@/components/layout/Scrollable';

import { SecondaryButton } from '@/components/common/Buttons';
import Divider from '@/components/common/DividerTwo';
import FlexColumn from '@/components/common/FlexColumn';
import Modal from '@/components/common/Modal';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';

import KidcardLogo from '@/components/svg/KidcardLogo';
import NotebirdLogo from '@/components/svg/NotebirdLogo';

// Styles
const CloseButton = styled(SecondaryButton)`
  position: absolute;
  top: 12px;
  right: 12px;
`;
const NotebirdInc = styled.div`
  font-size: 22px;
  font-weight: bold;
`;
const Small = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.textFaded};
  strong {
    color: ${({ theme }) => theme.textTertiary};
  }
  a {
    font-weight: bold;
  }
`;
const Address = styled.div`
  padding-left: 8px;
  border-left: 4px solid ${({ theme }) => theme.dividerColor};
  line-height: 1.25;
  color: ${({ theme }) => theme.textTertiary};
  strong {
    color: ${({ theme }) => theme.textSecondary};
  }
`;
const SocialRow = styled.div`
  a {
    margin-right: 8px;
    transition: transform 200ms ${({ theme }) => theme.easeStandard};
    svg path {
      fill: ${({ theme }) => theme.textFaded};
    }
  }
  a:hover {
    transform: scale(1.2);
    svg path {
      fill: ${({ theme }) => theme.linkColor};
    }
  }
`;
const Footer = styled.div`
  padding: 16px 32px 24px;
  text-align: center;
  background-color: ${({ theme }) => theme.backgroundColor};
`;

// Component
export default function AboutNotebirdModal() {
  const { isAboutNotebirdModalOpen, kidcardMode } = useAppState();
  const setAppState = useSetAppState();

  // If user click the logo 5 times, a support diagnostics report
  // gets sent to the Notebird Slack channel
  const [diagnosticsClickCount, setDiagnosticsClickCount] = useState(0);
  useEffect(() => {
    if (diagnosticsClickCount === 5) {
      // console.log('sending diagnostics report!');
    }
  }, [diagnosticsClickCount]);
  // Reset click count when closed
  useEffect(() => {
    if (!isAboutNotebirdModalOpen) {
      setDiagnosticsClickCount(0);
    }
  }, [isAboutNotebirdModalOpen]);

  return (
    <Modal handleClose={() => setAppState({ isAboutNotebirdModalOpen: false })}>
      {isAboutNotebirdModalOpen && (
        <FlexColumn style={{ position: 'relative' }}>
          {/* Close/exit button */}
          <CloseButton
            leadingIcon='close'
            onClick={() => setAppState({ isAboutNotebirdModalOpen: false })}
          />

          {/* Logo and version ( plus diagnostics sent notice ) */}
          <Padding padding='48px 32px 0'>
            {/* eslint-disable-next-line */}
            <div
              onClick={() =>
                setDiagnosticsClickCount(prev => {
                  const newCount = prev + 1;
                  if (newCount % 5 === 0) {
                    setAppState({ kidcardMode: !kidcardMode });
                  }
                  return newCount;
                })
              }
            >
              {kidcardMode ? (
                <KidcardLogo width='192px' />
              ) : (
                <NotebirdLogo width='192px' prefix='about-modal-' />
              )}
            </div>
            <Spacer height='4px' />
            <Small>
              Version <strong>{import.meta.env.VITE_VERSION}</strong>
            </Small>
            {/* {diagnosticsClickCount >= 5 ? (
              <Small>Hey... that tickles!</Small>
            ) : (
              <Small>
                Version <strong>{import.meta.env.VITE_VERSION}</strong>
              </Small>
            )} */}
          </Padding>
          <Spacer height='32px' />
          <Divider />

          {/* Main content */}
          <Scrollable padding='32px 32px 0' endBuffer='64px'>
            {/* Name and email */}
            <NotebirdInc>Notebird Inc.</NotebirdInc>
            <Spacer height='2px' />
            <a href='mailto:support@notebird.app'>support@notebird.app</a>
            <Spacer height='16px' />

            {/* Address */}
            <Address>
              PO Box 53223
              <br />
              Oklahoma City, OK 73152
              <br />
              United States, Earth
            </Address>
            <Spacer height='32px' />

            {/* Social row */}
            {/* ( svg icons found here: https://iconmonstr.com/ ) */}
            <SocialRow>
              {/* Facebook */}
              <a
                href='https://www.facebook.com/notebirdapp'
                rel='noopener noreferrer'
                target='_blank'
              >
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                  <path d='M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 10h-2v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667h1.442v-2.5h-2.404c-1.798 0-2.596.792-2.596 2.308v1.692z' />
                </svg>
              </a>
              {/* Instagram */}
              <a
                href='https://www.instagram.com/notebirdapp'
                rel='noopener noreferrer'
                target='_blank'
              >
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                  <path d='M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.615 6h-9.23c-.766 0-1.385.62-1.385 1.384v9.23c0 .766.619 1.386 1.385 1.386h9.23c.766 0 1.385-.62 1.385-1.385v-9.23c0-.765-.619-1.385-1.385-1.385zm-4.615 3.693c1.274 0 2.309 1.032 2.309 2.307s-1.035 2.307-2.309 2.307-2.307-1.033-2.307-2.307 1.033-2.307 2.307-2.307zm4.5 6.346c0 .255-.207.461-.461.461h-8.078c-.254 0-.461-.207-.461-.461v-5.039h.949c-.045.158-.078.32-.102.486-.023.168-.038.339-.038.514 0 2.04 1.652 3.693 3.691 3.693s3.691-1.653 3.691-3.693c0-.174-.015-.346-.039-.514-.023-.166-.058-.328-.102-.486h.95v5.039zm0-6.991c0 .255-.207.462-.461.462h-1.088c-.256 0-.461-.208-.461-.462v-1.087c0-.255.205-.461.461-.461h1.088c.254 0 .461.207.461.461v1.087z' />
                </svg>
              </a>
              {/* LinkedIn  */}
              <a
                href='https://www.linkedin.com/company/notebirdapp/'
                rel='noopener noreferrer'
                target='_blank'
              >
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                  <path d='M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 8c0 .557-.447 1.008-1 1.008s-1-.45-1-1.008c0-.557.447-1.008 1-1.008s1 .452 1 1.008zm0 2h-2v6h2v-6zm3 0h-2v6h2v-2.861c0-1.722 2.002-1.881 2.002 0v2.861h1.998v-3.359c0-3.284-3.128-3.164-4-1.548v-1.093z' />
                </svg>
              </a>
              {/* Pinterest  */}
              <a
                href='https://www.pinterest.com/notebirdapp/'
                rel='noopener noreferrer'
                target='_blank'
              >
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                  <path d='M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 6c-3.313 0-6 2.686-6 6 0 2.542 1.581 4.712 3.812 5.587-.052-.475-.1-1.203.022-1.721.108-.468.703-2.982.703-2.982s-.181-.359-.181-.891c0-.834.485-1.457 1.087-1.457.512 0 .759.385.759.845 0 .516-.328 1.285-.497 1.998-.142.598.3 1.084.889 1.084 1.066 0 1.887-1.124 1.887-2.747 0-1.437-1.032-2.441-2.507-2.441-1.707 0-2.709 1.28-2.709 2.604 0 .516.199 1.068.446 1.368.049.06.056.112.041.173l-.165.68c-.027.11-.088.134-.201.081-.75-.349-1.219-1.444-1.219-2.325 0-1.893 1.375-3.63 3.964-3.63 2.082 0 3.7 1.482 3.7 3.465 0 2.068-1.304 3.732-3.114 3.732-.608 0-1.179-.315-1.375-.689l-.374 1.426c-.135.521-.501 1.175-.746 1.573.562.173 1.16.267 1.778.267 3.313 0 6-2.687 6-6 0-3.314-2.687-6-6-6z' />
                </svg>
              </a>
            </SocialRow>
          </Scrollable>

          {/* Footer */}
          <Footer>
            {/* Cute "made with love" in OK */}
            <small>
              Made with{' '}
              <span role='img' aria-label='love'>
                ❤️
              </span>{' '}
              in Oklahoma
            </small>
            <Spacer height='16px' />

            {/* Terms/privacy links */}
            <Small>
              <a target='_blank' rel='noopener noreferrer' href='https://www.notebird.app/privacy'>
                Privacy Policy
              </a>{' '}
              ·{' '}
              <a target='_blank' rel='noopener noreferrer' href='https://www.notebird.app/terms'>
                Terms of Service
              </a>
            </Small>
            <Spacer height='4px' />

            {/* Copyright */}
            <Small>© {new Date().getFullYear()} Notebird Inc.</Small>
          </Footer>
        </FlexColumn>
      )}
    </Modal>
  );
}
