import styled, { css } from 'styled-components';

import Select, { type GroupBase, type Props } from 'react-select';

type FormSelectProps = {
  block?: boolean;
  relative?: boolean;
};
type FormSelectType = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: Props<Option, IsMulti, Group> & FormSelectProps
) => React.ReactElement;
const FormSelect = styled(Select).attrs({
  classNamePrefix: 'react-select',
  captureMenuScroll: false,
})<FormSelectProps>`
  flex-shrink: 0;
  min-width: 128px;

  ${({ block }) =>
    block &&
    css`
      width: 100%;
    `};

  .react-select__placeholder {
    max-width: calc(100% - 8px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.textFaded};
    letter-spacing: 0.025em;
  }

  .react-select__input {
    color: ${({ theme }) => theme.textPrimary} !important;
    letter-spacing: 0.025em;
  }

  .react-select__control {
    padding: 2px 0;
    color: ${({ theme }) => theme.textSecondary};
    background-color: ${({ theme }) => theme.sheetBackgroundColor};
    border: 2px solid ${({ theme }) => theme.lightAccent};
    border-radius: 4px;
    min-height: 46px;
    cursor: pointer;
    &:hover {
      border: 2px solid ${({ theme }) => theme.textTertiary};
    }
    &.react-select__control--is-focused {
      outline: none;
      border: 2px solid ${({ theme }) => theme.primary500};
      box-shadow: ${({ theme }) => theme.shadow300};
    }
    &.react-select__control--is-disabled {
      cursor: not-allowed;
      background-color: ${({ theme }) => theme.hoverFade};
      opacity: 0.5;
    }
  }

  .react-select__menu {
    /* z-index is to avoid competition with date picker icon ( especially on task form ) */
    z-index: 11;
    position: ${({ relative }) => (relative ? 'relative' : 'absolute')};
    background: none;
    box-shadow: none;
    margin-top: 2px;
    /* Extra padding is so we can assure the menu is completely scrolled into view on focus */
    /* taking into account fixed footer on floating sheet form ( but not when relative ) */
    /* padding-bottom: ${({ relative }) => (relative ? '0' : '64px')}; */
    pointer-events: none; /* To blur/close properly */
    .react-select__menu-list {
      pointer-events: auto;
      padding: 0;
      /* Styles are stolen from above to ensure 'scrollIntoView' calculates properly */
      background-color: ${({ theme }) => theme.sheetBackgroundColor};
      border: 2px solid ${({ theme }) => theme.offLight};
      box-shadow: ${({ theme }) => theme.shadow400};
      border-radius: 4px;
      .react-select__group {
        padding: 0;
        border-bottom: 1px solid ${({ theme }) => theme.dividerColor};
        &:last-child {
          border-bottom: none;
        }

        .react-select__group-heading {
          margin: 0;
          padding: 16px 16px 4px;
          text-transform: none;
          font-size: 14px;
          color: ${({ theme }) => theme.linkColor};
          font-weight: bold;
          &:empty {
            display: none;
          }
        }
      }

      .react-select__option {
        cursor: pointer;
        padding: 8px 16px;
      }
      .react-select__option--is-focused {
        background-color: ${({ theme }) => theme.hoverFade};
        &:active {
          background-color: ${({ theme }) => theme.offLight};
        }
      }
      .react-select__option--is-selected {
        background-color: ${({ theme }) => theme.primary500};
        &:active {
          background-color: ${({ theme }) => theme.primary600};
        }
        .count {
          color: ${({ theme }) => theme.primary100};
        }
      }
      .react-select__option--is-disabled {
        background-color: ${({ theme }) => theme.sheetBackgroundColor};
        color: ${({ theme }) => theme.avatarText};
        cursor: not-allowed;
      }
    }
  }

  .react-select__single-value {
    font-weight: bold;
    color: ${({ theme }) => theme.textSecondary};
  }

  .react-select__multi-value {
    background-color: ${({ theme }) => theme.hoverFade};
    .react-select__multi-value__label {
      padding: 6px 4px 6px 12px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.02em;
      color: ${({ theme }) => theme.textSecondary};
    }
    .react-select__multi-value__remove {
      padding: 0 8px;
      &:hover {
        background-color: ${({ theme }) => theme.danger400};
        color: ${({ theme }) => theme.danger000};
      }
    }
  }
` as FormSelectType;

export default FormSelect;
