import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import Doc from '@/classes/Doc';
import Person from '@/classes/Person';
import Task from '@/classes/Task';

import { MAX_MILLIS } from '@/lib/config';
import { getFriendlyDate } from '@/lib/helpers';

import groupBy from 'lodash/groupBy';
import intersection from 'lodash/intersection';
import orderBy from 'lodash/orderBy';
import toPairs from 'lodash/toPairs';
import { DateTime } from 'luxon';

import useAppState from '@/contexts/appState';
import useGroups from '@/contexts/groups';

import SheetHeader from '@/components/layout/SheetHeader';

import PrimaryButton, { SecondaryButton } from '@/components/common/Buttons';
import Spacer from '@/components/common/Spacer';
import StickyHeader from '@/components/common/StickyHeader';
import TaskItem from '@/components/common/TaskItem';
import TransitionList, { TransitionListWrapper } from '@/components/common/TransitionList';

// Props
interface Props {
  person: Doc<Person>;
  tasks: Doc<Task>[];
  setShowNewTaskForm: Dispatch<SetStateAction<boolean>>;
  setDeleteTaskDoc: Dispatch<SetStateAction<Doc<Task> | undefined>>;
  setEditTaskDoc: Dispatch<SetStateAction<Doc<Task> | undefined>>;
}
// Component
const TasksSection = ({
  person,
  tasks,
  setShowNewTaskForm,
  setEditTaskDoc,
  setDeleteTaskDoc,
}: Props) => {
  // App state
  const { uid, isActive, isLocationAdmin, isSingleUser } = useAppState();
  // Context data
  const [userGroups] = useGroups();

  // NOTE: This section shared, in large part, with a user's TasksAccordion
  ////////////////////////////////////////////////////////////////////////

  // Open/uncompleted tasks
  // grouped by day then flattened for proper accordion animating
  const uncompletedTasks = useMemo(() => tasks.filter(({ isCompleted }) => !isCompleted), [tasks]);
  const uncompletedFlatList: (string | Doc<Task>)[] = useMemo(() => {
    const today = DateTime.local().startOf('day');
    const grouped = groupBy(uncompletedTasks, ({ dueDate }) => {
      // Lump all 'unscheduled' together
      if (!dueDate) {
        return MAX_MILLIS;
      } else {
        const dueDateTime = DateTime.fromISO(dueDate).startOf('day');
        // Lump all 'overdue' together
        return dueDateTime < today ? 0 : dueDateTime.toMillis();
      }
    });
    return orderBy(toPairs(grouped), ([millis]) => millis).flat(2);
  }, [uncompletedTasks]);

  // Completed tasks ( and when to show them )
  // grouped by day then flattened for proper accordion animating
  const [showCompletedTasks, setShowCompletedTasks] = useState(false);
  const completedTasks = useMemo(() => tasks.filter(({ isCompleted }) => isCompleted), [tasks]);
  const completedFlatList: (string | Doc<Task>)[] = useMemo(() => {
    if (!showCompletedTasks) return [];
    const grouped = groupBy(completedTasks, task =>
      task.completedAt
        ? DateTime.fromMillis(task.completedAt.toMillis()).startOf('day').toMillis()
        : DateTime.local().startOf('day').toMillis()
    );
    return orderBy(toPairs(grouped), ([millis]) => millis, 'desc').flat(2);
  }, [completedTasks, showCompletedTasks]);
  // Whenever there are no completed tasks, reset show status
  useEffect(() => {
    !completedTasks.length && setShowCompletedTasks(false);
  }, [completedTasks.length]);

  // Determine if should show or hide group
  // ( person only in one group and it's the same one as task )
  // ( same as updates section )
  const shouldHideGroups = useCallback(
    (taskGroups: string[]) => {
      const userGroupIds = userGroups?.map(({ id }) => id) || [];
      const personGroupsInCommon = isLocationAdmin
        ? person.groups
        : intersection(person.groups, userGroupIds);
      const taskGroupsInCommon = isLocationAdmin
        ? taskGroups
        : intersection(taskGroups, userGroupIds);
      return (
        personGroupsInCommon.length === 1 &&
        taskGroupsInCommon.length === 1 &&
        personGroupsInCommon[0] === taskGroupsInCommon[0]
      );
    },
    [userGroups, isLocationAdmin, person.groups]
  );

  return (
    // This div is for proper sticky header scrolling
    <div>
      {/* Tasks sheet header ( with 'add' button) */}
      <SheetHeader
        sticky
        leadingIcon='check'
        mainTitle='Tasks'
        trailing={
          <PrimaryButton
            medium
            onClick={() => setShowNewTaskForm(true)}
            disabled={person.isArchived || !isActive}
            data-intercom-target='Add task button'
          >
            Add <strong>task</strong>
          </PrimaryButton>
        }
        active={!!uncompletedTasks.length || showCompletedTasks}
        subtitle={
          completedTasks.length ? (
            <SecondaryButton
              onClick={() => setShowCompletedTasks(prev => !prev)}
              dull={showCompletedTasks}
            >
              {showCompletedTasks ? 'Hide ' : 'Show '}
              {completedTasks.length} completed
            </SecondaryButton>
          ) : !uncompletedTasks.length ? (
            'No tasks for ' + person.profile.name.first
          ) : undefined
        }
        data-intercom-target='Person tasks header'
      />

      {/* Task lists */}
      <TransitionListWrapper>
        {/* Uncompleted tasks */}
        {uncompletedFlatList.map(value => {
          // Date/group header
          if (typeof value === 'string') {
            const millis = parseInt(value);
            const isOverdue = millis === 0;
            const isUnscheduled = millis === MAX_MILLIS;
            return (
              <TransitionList key={value} style={{ position: 'sticky', top: 64, zIndex: 8 }}>
                <StickyHeader
                  heading={
                    isOverdue
                      ? 'Overdue'
                      : isUnscheduled
                        ? 'Unscheduled'
                        : 'Due ' + getFriendlyDate(millis)
                  }
                  variant={isOverdue ? 'danger' : isUnscheduled ? 'dull' : 'normal'}
                />
              </TransitionList>
            );
          }
          // Task item
          const task = value;
          return (
            <TransitionList key={task.id}>
              <TaskItem
                key={task.id}
                task={task}
                setEditTaskDoc={setEditTaskDoc}
                setDeleteTaskDoc={setDeleteTaskDoc}
                canEdit={
                  isActive &&
                  !person.isArchived &&
                  (isLocationAdmin ||
                    task.meta.createdBy.id === uid ||
                    (!!task.assignedTo && task.assignedTo.id === uid))
                }
                hidePerson
                // Hide groups here if user only has one group in common
                // and the task includes this group
                hideGroups={shouldHideGroups(task.groups)}
                hideUser={isSingleUser}
              />
            </TransitionList>
          );
        })}

        {/* Completed tasks */}
        {completedFlatList.map(value => {
          // Date/group header
          if (typeof value === 'string') {
            const millis = parseInt(value);
            return (
              <TransitionList key={value} style={{ position: 'sticky', top: 64, zIndex: 8 }}>
                <StickyHeader heading={`Completed ${getFriendlyDate(millis)}`} variant='dull' />
              </TransitionList>
            );
          }
          // Task item
          const task = value;
          return (
            <TransitionList key={task.id}>
              <TaskItem
                key={task.id}
                task={task}
                hidePerson
                // Hide groups here if user only has one group in common
                // and the task includes this group
                hideGroups={shouldHideGroups(task.groups)}
                hideUser={isSingleUser}
              />
            </TransitionList>
          );
        })}
        {/* Only need spacer at end if has open tasks ( and not showing or has no completed ) */}
        {/* becuase it's needed to fully open edit/delete menu flyout */}
        {!!uncompletedTasks.length && !completedFlatList.length && (
          <TransitionList>
            <Spacer height='24px' />
          </TransitionList>
        )}
      </TransitionListWrapper>
    </div>
  );
};

// Export
export default TasksSection;
