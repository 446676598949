import styled from 'styled-components';

import { useFormikContext } from 'formik';

import FormError from '@/components/formElements/styled/FormError';

// Props
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  children?: React.ReactNode;
}

// Styles
const Label = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
`;
const Checkbox = styled.input<{ hasError: boolean } & Props>`
  appearance: none;
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;

  border: 2px solid ${({ hasError, theme }) => (hasError ? theme.danger500 : theme.grey200)};
  border-radius: 4px;
  transition:
    border-color 100ms ease-out,
    box-shadow 100ms ease-out;

  &:after {
    content: 'check';
    font-family: 'Material Icons Round';
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    top: -1px;
    opacity: 0;
    color: white;
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      outline: none;
      border: 2px solid ${({ hasError, theme }) => (hasError ? theme.danger800 : theme.grey400)};
      box-shadow: ${props => props.theme.shadow200};
    }
    &:hover {
      &:after {
        color: ${props => props.theme.grey200};
        color: ${({ hasError, theme }) => (hasError ? theme.danger200 : theme.grey200)};
        opacity: 1;
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  &[type='checkbox']:checked {
    background-color: ${props => props.theme.primary500};
    border: 2px solid ${props => props.theme.primary500};
    &:hover,
    &:focus {
      border: 2px solid ${props => props.theme.primary800};
      color: ${props => props.theme.primary100};
    }

    &:after {
      color: ${props => props.theme.primary000};
      opacity: 1;
    }
  }
`;
const Children = styled.div`
  padding: 12px;
  color: ${props => props.theme.textTertiary};
`;

// Component
const FormikCheckbox = ({ name, disabled, children, ...props }: Props) => {
  const { isSubmitting, getFieldProps, getFieldMeta } = useFormikContext();
  const field = getFieldProps({ name, type: 'checkbox' });
  const meta = getFieldMeta(name);
  return (
    <>
      <Label htmlFor={name} className={disabled || isSubmitting ? 'disabled' : ''}>
        <Checkbox
          id={name}
          type='checkbox'
          disabled={disabled || isSubmitting}
          {...field}
          {...props}
          hasError={meta.touched && !!meta.error}
        />
        <Children>{children}</Children>
      </Label>
      {meta.touched && !!meta.error ? <FormError>{meta.error}</FormError> : null}
    </>
  );
};
export default FormikCheckbox;
