import { Route, Routes } from 'react-router-dom';

import ForgotPasswordPage from '@/components/pages/ForgotPasswordPage';
import LogInPage from '@/components/pages/LogInPage';
import SignUpPage from '@/components/pages/SignUpPage';

// Component
const AuthRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<LogInPage />} />
      <Route path='/login/:email?' element={<LogInPage />} />
      <Route path='/signup/:email?' element={<SignUpPage />} />
      <Route path='/forgot/:email?' element={<ForgotPasswordPage />} />
      <Route path='*' element={<LogInPage />} />
    </Routes>
  );
};
export default AuthRoutes;
