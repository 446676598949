import Doc from '@/classes/Doc';
import Meta from '@/classes/Meta';
import User from '@/classes/User';

import firebase from '@/lib/firebase';

//** Special hook to generate metadata for a new doc */
export default function generateCreatedByMeta(user: Doc<User>) {
  const meta: Meta = {
    ...new Meta(),
    createdBy: {
      id: user.id,
      profile: user.profile,
    },
    createdAt: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
  };
  return meta;
}

//** Special function to generate data to add to existing meta when updating */
export function generateUpdatedByMeta(user: Doc<User>, prevMeta: Meta) {
  const meta: Meta = {
    ...prevMeta,
    updatedBy: {
      id: user.id,
      profile: user.profile,
    },
    updatedAt: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
  };
  return meta;
}

//** Special function to generate data to add to existing meta when updating ( using not notation ) */
export function generateUpdatedByDottedMeta(user: Doc<User>) {
  return {
    'meta.updatedBy': {
      id: user.id,
      profile: user.profile,
    },
    'meta.updatedAt':
      firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
  };
}
