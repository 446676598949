import { createRoot } from 'react-dom/client';

import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';
import '@fontsource/material-icons-round';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { IntercomProvider } from 'react-use-intercom';

import { AppStateProvider } from '@/contexts/appState';
import { AuthProvider } from '@/contexts/auth';
import { CurrentReleaseProvider } from '@/contexts/currentRelease';

import App from './App';

// Initialize posthog
const MASK_WHITELIST = [
  'notebird',
  'email address',
  'password',
  'log in',
  'people',
  'all people',
  'activity',
  'updates',
  'milestones',
  'tasks',
  'my tasks',
  'completed tasks',
  'user profile',
  'my team',
  'account',
  'customizations',
  'reports',
  'chat with us',
  'help center',
  'about notebird',
  'log out',
];

posthog.init(import.meta.env.VITE_POSTHOG_TOKEN, {
  // Reverse proxy via Cloudflare pointing to app.posthog.com
  api_host: 'https://chirp.notebird.app',
  capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  session_recording: {
    maskAllInputs: true,
    maskTextSelector: ':not(.material-icons-round)',
    maskTextFn: text => {
      // If text is in whitelist, don't mask
      if (MASK_WHITELIST.includes(text.toLowerCase())) return text;
      // If text is in format string_string or string_string_string, don't mask. It's probably an icon.
      // (:not(i)) is not currently working. waiting on a response from posthog support.
      if (text.match(/^\w+_\w+(?:_\w+){0,2}$/)) return text;
      // Otherwise, mask the text content
      return '*'.repeat(text.length);
    },
  },
  disable_session_recording: true,
});

// Render root
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <PostHogProvider client={posthog}>
    <CurrentReleaseProvider>
      <AppStateProvider>
        <AuthProvider>
          <IntercomProvider appId={import.meta.env.VITE_INTERCOM_APP_ID}>
            <App />
          </IntercomProvider>
        </AuthProvider>
      </AppStateProvider>
    </CurrentReleaseProvider>
  </PostHogProvider>
);
