import styled from 'styled-components';

import Spacer from '@/components/common/Spacer';

// Styles
const StyledScrollable = styled.div`
  position: relative;
  flex: 1;

  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;

  /* IE10+ CSS styles go here */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: 0 1 auto;
  }
`;

// Props
interface Props {
  padding?: string;
  endBuffer?: string;
  forwardedRef?: React.RefObject<HTMLDivElement>;
  className?: string;
  children: React.ReactNode;
}

// Component
const Scrollable = ({
  padding = '0',
  endBuffer = '32px',
  forwardedRef,
  className,
  children,
}: Props) => (
  <StyledScrollable ref={forwardedRef} style={{ padding }} className={className}>
    {children}
    {!!endBuffer && <Spacer height={endBuffer} />}
  </StyledScrollable>
);
export default Scrollable;
