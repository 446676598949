import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import Photo from '@/classes/Photo';

import { useField, useFormikContext } from 'formik';
import { useDropzone } from 'react-dropzone';
import type { Area } from 'react-easy-crop/types';

import type { EmptyValues, PhotoFile } from './PersonForm';

import PhotoCropper from '@/components/formElements/PhotoCropper';
import FormError from '@/components/formElements/styled/FormError';

import Avatar from '@/components/common/Avatar';
import { SecondaryButton } from '@/components/common/Buttons';
import Icon from '@/components/common/Icon';
import Spacer from '@/components/common/Spacer';

// Styles
const DeletePhotoFlexWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 16px;

  border-radius: 6px;
  background-color: ${props => props.theme.hoverFade};
  border: 2px solid ${props => props.theme.hoverFade};

  /* Disabled */
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;
const PhotoCropperFlexWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  border-radius: 6px;
  background-color: ${props => props.theme.hoverFade};

  /* Disabled */
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;
const PhotoDropzone = styled.div<{ dragActive: boolean; disabled: boolean }>`
  appearance: none;
  outline: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 8px 16px;

  background-color: ${props => props.theme.hoverFade};
  border: 2px dashed
    ${({ dragActive, theme }) => (dragActive ? theme.primary500 : theme.lightAccent)};
  border-radius: 6px;

  color: ${props => props.theme.textFaded};
  i {
    margin-right: 8px;
    color: ${props => props.theme.linkColor};
  }

  &:hover {
    border: 2px dashed ${({ theme }) => theme.textTertiary};
  }
  &:focus {
    border: 2px dashed ${({ theme }) => theme.primary500};
    color: ${props => props.theme.textSecondary};
    i {
      color: ${props => props.theme.linkHover};
    }
  }

  /* Disabled */
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;
const PhotoPlaceholder = styled.div`
  flex-shrink: 0;
  width: 64px;
  height: 64px;
  margin-right: 24px;
  border-radius: 50%;

  background-color: ${({ theme }) => theme.avatarBG};
  background-size: auto 100%;
  background-position: calc(50% + 0px) calc(50% + 0px);
  background-repeat: no-repeat;
`;

// Config
const ACCEPTED_FILETYPES = { 'image/*': ['.jpg', '.jpeg', '.png'] };
const MAX_FILESIZE = 8000000; // 8mb

// Props
interface Props {
  currentPhoto?: Photo;
}
// Component
const FormikPhotoManager = ({ currentPhoto }: Props) => {
  const { isSubmitting, setFieldValue } = useFormikContext<EmptyValues>();
  const [{ value: photoFile }] = useField<PhotoFile | undefined>('photoFile');
  const [{ value: deletePhoto }] = useField<boolean>('deletePhoto');

  // Show photo dropzone if doesn't have photo selected AND
  // 1. Doesn't have a photo yet
  // or
  // 2. Has an photo already but is deleting it
  // or
  // 3. photo is pending
  const showDropzone =
    !photoFile &&
    (!currentPhoto || // 1
      (currentPhoto && deletePhoto) || // 2
      currentPhoto === 'pending'); //3

  // Errors if improperly uploading photo
  const [photoError, setPhotoError] = useState('');

  // Props received back from PhotoCropper
  const handlePhotoCropComplete = useCallback(
    (croppedAreaPixels: Area) => {
      const { x, y, width, height } = croppedAreaPixels;
      setFieldValue('photoCrop', {
        cropX: x.toString(),
        cropY: y.toString(),
        cropWidth: width.toString(),
        cropHeight: height.toString(),
      });
    },
    [setFieldValue]
  );

  // https://react-dropzone.netlify.com/
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: () => {
      setPhotoError(''); // Reset error
    },
    onDropAccepted: files => {
      const file: PhotoFile = Object.assign(files[0], {
        preview: URL.createObjectURL(files[0]),
      });
      setFieldValue('photoFile', file);
    },
    onDropRejected: rejections => {
      const errorCode = rejections[0]?.errors[0]?.code ?? 'unknown';
      switch (errorCode) {
        case 'file-invalid-type':
          setPhotoError('Only .jpg and .png file types supported');
          break;
        case 'file-too-large':
          setPhotoError('Too big! Files should be less than 8mb');
          break;
        case 'too-many-files':
          setPhotoError('Only 1 file allowed at a time');
          break;
        default:
          setPhotoError('An error occurred. Please try again or contact support.');
      }
    },
    multiple: false,
    disabled: isSubmitting,
    accept: ACCEPTED_FILETYPES,
    maxSize: MAX_FILESIZE,
  });
  // This revokes the data uri on unmount (if present) to "avoid memory leaks"
  useEffect(
    () => () => {
      photoFile && URL.revokeObjectURL(photoFile.preview);
    },
    [photoFile]
  );
  // End picture upload-drag and drop stuff

  return (
    <>
      {/* Photo to remove if editing */}
      {!photoFile && !showDropzone && currentPhoto instanceof Object && (
        <DeletePhotoFlexWrapper disabled={isSubmitting}>
          <Avatar photo={currentPhoto} size={64} />
          <Spacer width='16px' />
          <SecondaryButton
            leadingIcon='close'
            dull
            onClick={() => setFieldValue('deletePhoto', true)}
          >
            Remove
          </SecondaryButton>
        </DeletePhotoFlexWrapper>
      )}
      {/* Dropzone (if new person or editing without photo or deleted photo) */}
      {showDropzone && (
        <>
          <PhotoDropzone {...getRootProps()} dragActive={isDragActive} disabled={isSubmitting}>
            {!isDragActive && (
              <>
                <Icon icon='cloud_upload' iconSize='20px' />
                <span>
                  Upload <strong>profile pic</strong>
                </span>
              </>
            )}
            {isDragActive && (
              <>
                <PhotoPlaceholder />
                <strong>Drop to upload...</strong>
              </>
            )}
            <input {...getInputProps()} />
          </PhotoDropzone>
          {photoError && <FormError>{photoError}</FormError>}
        </>
      )}
      {/* Cropper */}
      {!!photoFile && (
        <PhotoCropperFlexWrapper disabled={isSubmitting}>
          <PhotoCropper previewUrl={photoFile.preview} handleOnComplete={handlePhotoCropComplete} />
          <Spacer width='16px' />
          <SecondaryButton
            leadingIcon='close'
            dull
            onClick={() => setFieldValue('photoFile', undefined)}
          >
            Remove
          </SecondaryButton>
        </PhotoCropperFlexWrapper>
      )}
    </>
  );
};
export default FormikPhotoManager;
