import type { DefaultTheme } from 'styled-components';

import { darkSemantics, darkShadows } from './darkTheme';
import ParakeetTheme, { parakeetColors } from './parakeetTheme';

const parakeetThemeDark: DefaultTheme = {
  ...ParakeetTheme,
  isDark: true,

  /////////////////////
  // Semantic Colors //
  /////////////////////
  ...darkSemantics,
  linkColor: parakeetColors.primary400,
  linkHover: parakeetColors.primary300,
  primaryDark: parakeetColors.primary200,

  /////////////
  // Shadows //
  /////////////
  ...darkShadows,
};

export default parakeetThemeDark;
