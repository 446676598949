import styled from 'styled-components';

import { useFormikContext } from 'formik';

import FormError from '@/components/formElements/styled/FormError';

// Props
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  small?: boolean;
  children?: React.ReactNode;
}

// Styles
const ToggleLabel = styled.label`
  display: inline-flex;
  align-items: center;

  cursor: pointer;
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

const ToggleWrapper = styled.div<{ small?: boolean }>`
  flex-shrink: 0;
  position: relative;
  display: inline-block;
  width: ${({ small }) => (small ? '30px' : '38px')};
  height: ${({ small }) => (small ? '18px' : '22px')};
`;
const ToggleInput = styled.input<{ hasError: boolean } & Props>`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: ${({ theme }) => theme.primary500};
    &:before {
      background-color: white;
      transform: translateX(${({ small }) => (small ? '12px' : '16px')});
    }

    &:hover {
      background-color: ${({ theme }) => theme.primary600};
      &:before {
        background-color: ${({ theme }) => theme.grey100};
      }
    }
  }
  &:focus + span {
    box-shadow: 0 0 1px ${({ theme }) => theme.primary600};
  }
`;
const ToggleDot = styled.span<{ small?: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  background-color: ${({ theme }) => theme.grey200};
  transition: background-color 250ms;
  &:before {
    position: absolute;
    content: '';
    width: ${({ small }) => (small ? '12px' : '16px')};
    height: ${({ small }) => (small ? '12px' : '16px')};
    left: 3px;
    bottom: 3px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.grey600};
    transition:
      transform 250ms ${({ theme }) => theme.easeStandard},
      background-color 250ms;
  }

  &:hover {
    box-shadow: ${props => props.theme.shadow200};
    background-color: ${({ theme }) => theme.grey100};
    &:before {
      background-color: ${({ theme }) => theme.grey500};
    }
  }
`;
const Children = styled.div<{ small?: boolean }>`
  flex: 1;
  padding: ${({ small }) => (small ? '8px' : '12px')};
`;

// Component
const FormikToggle = ({ name, small = false, disabled, children, ...props }: Props) => {
  const { isSubmitting, getFieldProps, getFieldMeta } = useFormikContext();
  const field = getFieldProps({ name, type: 'checkbox' });
  const meta = getFieldMeta(name);

  return (
    <>
      <ToggleLabel htmlFor={name} className={disabled || isSubmitting ? 'disabled' : ''}>
        <ToggleWrapper small={small}>
          <ToggleInput
            id={name}
            type='checkbox'
            disabled={disabled || isSubmitting}
            {...field}
            {...props}
            hasError={meta.touched && !!meta.error}
            small={small}
          />
          <ToggleDot small={small} />
        </ToggleWrapper>

        <Children small={small}>{children}</Children>
      </ToggleLabel>
      {meta.touched && !!meta.error ? <FormError>{meta.error}</FormError> : null}
    </>
  );
};
export default FormikToggle;
