import { useCallback, useEffect, useState } from 'react';

import { useField, useFormikContext } from 'formik';

import FormInputGroup from '@/components/formElements/styled/FormInputGroup';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactNode;
  labelIcon?: string;
  optional?: boolean;
  name: string;
  dense?: boolean;
  isClearable?: boolean;
}

const FormikTimePicker = ({ name, disabled, isClearable = true, ...rest }: Props) => {
  const { isSubmitting, setFieldValue } = useFormikContext();
  const [{ onBlur, ...field }, meta] = useField(name);
  // When 'isClearable' is false, we want to keep the last valid value and restore it when the user
  // blurs the input with no value. This is currently only used for the 'time' field in the 'UpdateForm'
  const updateValue = isClearable ? null : field.value;
  const [lastValidValue, setLastValidValue] = useState(updateValue);
  useEffect(() => {
    if (
      !isClearable &&
      typeof updateValue !== 'undefined' &&
      updateValue !== null &&
      (typeof updateValue !== 'string' || updateValue.trim())
    ) {
      setLastValidValue(updateValue);
    }
  }, [isClearable, updateValue]);

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (isClearable) return onBlur(e);

      if (e.target.value.trim() === '') setFieldValue(name, lastValidValue);
      onBlur(e);
    },
    [isClearable, lastValidValue, name, onBlur, setFieldValue]
  );
  return (
    <FormInputGroup
      error={meta.touched ? meta.error : undefined}
      disabled={disabled || isSubmitting}
      onBlur={handleBlur}
      {...rest}
      {...field}
      type='time'
    />
  );
};
export default FormikTimePicker;
