import { createContext, useContext, useEffect } from 'react';

import Doc from '@/classes/Doc';
import { UserProfile } from '@/classes/User';

import { db } from '@/lib/firebase';

import useCollection from '@/hooks/useCollection';

import useAppState, { useSetAppState } from './appState';
import useOrganization from './organization';

// Context ( with hook shortcut )
const fetchingUsers: [Doc<UserProfile>[] | null, boolean] = [null, true];
const usersContext = createContext(fetchingUsers);
const useUsers = () => useContext(usersContext);
export default useUsers;

// Context definition w/ provider
export const UsersProvider = ({ children }: { children: React.ReactNode }) => {
  // App state
  const { organizationId, isOnboarding } = useAppState();
  const setAppState = useSetAppState();
  // Context data
  const [organization] = useOrganization();

  const users = useCollection<UserProfile>(
    !!organizationId && !isOnboarding && db.collection(`organizations/${organizationId}/_users`)
  );

  // Keep `isSingleUser` status up to date in appState
  // ( filtering out archived users )
  const totalUsers = users[0]?.length || 1;
  const archivedUsers = organization?.archivedUsers.length || 0;
  const isSingleUser = totalUsers - archivedUsers <= 1;
  useEffect(() => {
    setAppState({ isSingleUser });
  }, [isSingleUser, setAppState]);

  return <usersContext.Provider value={users}>{children}</usersContext.Provider>;
};
