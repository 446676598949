import { useMemo } from 'react';

import Doc from '@/classes/Doc';
import Milestone from '@/classes/Milestone';
import Person from '@/classes/Person';

import { db, fbAnalytics } from '@/lib/firebase';
import { logEvent } from 'firebase/analytics';

import generateCreatedByMeta, { generateUpdatedByDottedMeta } from '@/lib/helpers/generateMeta';

import { Formik } from 'formik';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { DateTime } from 'luxon';
import { usePostHog } from 'posthog-js/react';
import { object, string } from 'yup';

import useOrganization from '@/contexts/organization';
import useUser from '@/contexts/user';

import Scrollable from '@/components/layout/Scrollable';

import FormikCheckbox from '@/components/formElements/FormikCheckbox';
import FormikDatePicker from '@/components/formElements/FormikDatePicker';
import FormikFooter from '@/components/formElements/FormikFooter';
import FormikForm from '@/components/formElements/FormikForm';
import FormikHeader from '@/components/formElements/FormikHeader';
import FormikSelect from '@/components/formElements/FormikSelect';
import FormikTextarea from '@/components/formElements/FormikTextarea';

import DescriptionBlock from '@/components/common/DescriptionBlock';
import Divider from '@/components/common/DividerTwo';
import Expanded from '@/components/common/Expanded';
import FlexColumn from '@/components/common/FlexColumn';
import FlexRow from '@/components/common/FlexRow';
import Margin from '@/components/common/Margin';
import Spacer from '@/components/common/Spacer';

// Form validation
const schema = object().shape({
  description: string().trim().max(150, '150 characters max'),
});

// Props
interface Props {
  person: Doc<Person>;
  milestone?: Doc<Milestone>;
  handleCancel: () => void;
}

// Component
const MilestoneForm = ({ person, milestone, handleCancel }: Props) => {
  const [user] = useUser();
  const [organization] = useOrganization();
  const posthog = usePostHog();

  // Establish initial values ( new vs editing )
  const initialValues = useMemo(() => {
    const { type, date, showAnnually, description } = milestone || { ...new Milestone() };
    return {
      type,
      date,
      showAnnually,
      description,
    };
  }, [milestone]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={values => {
        // EDIT / UPDATE
        ////////////////
        if (user && organization) {
          if (milestone) {
            db.doc(milestone.docPath).update({
              ...generateUpdatedByDottedMeta(user),
              // Core
              ...values,
              // Trim and reduce multiline linebreaks to just one:
              // https://stackoverflow.com/questions/10965433/regex-replace-multi-line-breaks-with-single-in-javascript
              description: values.description.trim().replace(/\n\s*\n\s*\n/g, '\n'),
              // Make sure to update Mmddyyyy value too!
              dateMmddyyyy: DateTime.fromISO(values.date).toFormat('MMddyyyy'),
            });
          }
          // CREATE NEW
          /////////////
          else {
            const newMilestone: Milestone = {
              // Created meta
              meta: generateCreatedByMeta(user),
              // Location and groups ( same as person )
              locationId: person.locationId,
              groups: person.groups,
              // Linked person
              person: {
                id: person.id,
                profile: person.profile,
              },
              // Always start non-archived
              isArchived: false,
              // Core
              ...values,
              // Trim and reduce multiline linebreaks to just one:
              // https://stackoverflow.com/questions/10965433/regex-replace-multi-line-breaks-with-single-in-javascript
              description: values.description.trim().replace(/\n\s*\n\s*\n/g, '\n'),
              // Make sure to set Mmddyyyy value too!
              dateMmddyyyy: DateTime.fromISO(values.date).toFormat('MMddyyyy'),
            };
            db.collection(`organizations/${organization.id}/milestones`).doc().set(newMilestone);
          }
          // Analytics
          const analyticsProps = {
            type: values.type,
            show_annually: values.showAnnually,
            description_length: values.description.length,
          };
          posthog?.capture(!milestone ? 'milestone_created' : 'milestone_edited', analyticsProps);
          logEvent(fbAnalytics, !milestone ? 'milestone_create' : 'milestone_edit', analyticsProps);
        }
        // Close form when finished
        handleCancel();
      }}
    >
      <FormikForm
        intercomTarget='Milestone form'
        handleCancel={handleCancel}
        // Dissallow submissions when either type or date are empty OR
        // editing milestone and nothing in form values has changed
        disableSubmit={(values: typeof initialValues) =>
          !values.type.trim() || !values.date || (!!milestone && isEqual(initialValues, values))
        }
      >
        <FlexColumn>
          {/* Header */}
          <FormikHeader
            leadingIcon={milestone ? 'edit' : 'flag'}
            mainTitle={milestone ? 'Edit milestone' : 'New milestone'}
            handleCancel={handleCancel}
          />

          {/* Form body */}
          <Scrollable>
            {/* Manage Bar/Bat Miztvahs and Yahrzeits in ShulCloud notice ( if linked ) */}
            {organization?._integration?.service === 'shulCloud' && !!person.integration?.id && (
              <DescriptionBlock>
                Manage <strong>Bar/Bat Miztvahs</strong> and <strong>Yahrzeits</strong> within the{' '}
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={`${organization._integration.url}/admin/members.php?action=view&id=${person.integration.households[0]}`}
                >
                  ShulCloud Account
                </a>{' '}
                for {person.profile.name.first}. All other custom milestones can be handled
                separately.{' '}
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://guide.notebird.app/articles/3657333'
                >
                  Learn more
                </a>
              </DescriptionBlock>
            )}

            {/* Section 1 ( type, date, showAnnually ) */}
            <Margin margin='32px 48px 32px' mobileMargin='32px 16px'>
              <FlexRow>
                {/* Milestone type */}
                <Expanded>
                  <FormikSelect
                    label='Milestone type'
                    name='type'
                    placeholder='Select type'
                    options={sortBy(organization?.preferences.milestoneTypes || []).map(type => ({
                      value: type,
                      label: type,
                    }))}
                    isClearable
                  />
                </Expanded>
                <Spacer width='16px' />

                {/* Milestone date */}
                <Expanded>
                  <FormikDatePicker label='Date' name='date' />
                </Expanded>
              </FlexRow>
              <Spacer height='16px' />

              {/* Show annually checkbox */}
              <FormikCheckbox name='showAnnually'>
                Show annually in <em>activity</em>
              </FormikCheckbox>
            </Margin>
            <Divider margin={24} />

            {/* Section 2 ( description ) */}
            <Margin margin='32px 48px 0' mobileMargin='32px 16px 0'>
              <FormikTextarea
                label='Short description'
                optional
                name='description'
                minRows={1}
                maxLength={200}
              />
            </Margin>
          </Scrollable>

          {/* Footer */}
          <FormikFooter
            handleCancel={handleCancel}
            submitButton={
              <>
                <strong>Save</strong> milestone
              </>
            }
          />
        </FlexColumn>
      </FormikForm>
    </Formik>
  );
};
export default MilestoneForm;
