import SheetHeader from '@/components/layout/SheetHeader';

import PrimaryButton, { DangerButton, SecondaryButton } from '@/components/common/Buttons';
import Spacer from '@/components/common/Spacer';

// Props
interface Props {
  handleSubmit?(): void;
  handleDelete?(): void;
  handleCancel?(): void;
  isSubmitting?: boolean;
  submitDisabled?: boolean;
  submitButton?: React.ReactNode;
  danger?: boolean;
  submitIntercomTarget?: string;
  leading?: React.ReactNode;
}
// Component
const FormFooter = ({
  handleSubmit,
  handleDelete,
  handleCancel,
  submitButton = 'Save',
  isSubmitting,
  submitDisabled,
  danger = false,
  submitIntercomTarget = 'Submit button',
  ...rest
}: Props = {}) => (
  <SheetHeader
    // Delete button
    leading={
      !!handleDelete && (
        <SecondaryButton
          danger
          onClick={event => {
            event.preventDefault();
            handleDelete();
          }}
          disabled={isSubmitting}
        >
          Delete
        </SecondaryButton>
      )
    }
    // Cancel + Save
    trailing={
      <>
        {/* Cancel */}
        {!!handleCancel && (
          <SecondaryButton
            dull
            onClick={event => {
              event.preventDefault();
              handleCancel();
            }}
            disabled={isSubmitting}
          >
            Cancel
          </SecondaryButton>
        )}
        <Spacer width='16px' />
        {/* Save */}
        {danger ? (
          <DangerButton
            type='submit'
            disabled={isSubmitting || submitDisabled}
            onClick={
              handleSubmit
                ? event => {
                    event.preventDefault();
                    handleSubmit();
                  }
                : undefined
            }
            data-intercom-target={submitIntercomTarget}
          >
            {submitButton}
          </DangerButton>
        ) : (
          <PrimaryButton
            type='submit'
            disabled={isSubmitting || submitDisabled}
            onClick={
              handleSubmit
                ? event => {
                    event.preventDefault();
                    handleSubmit();
                  }
                : undefined
            }
            data-intercom-target={submitIntercomTarget}
          >
            {submitButton}
          </PrimaryButton>
        )}
      </>
    }
    {...rest}
  />
);

export default FormFooter;
