import { Dispatch, SetStateAction, useEffect, useState } from 'react';

function useDebouncedState<T>({
  defaultValue,
  shouldSyncDefault = false,
  wait = 500,
  debouncedCallback,
  callbackIfEqual = false,
}: {
  defaultValue: T;
  shouldSyncDefault?: boolean;
  wait?: number;
  debouncedCallback?(debouncedValue: T): void;
  callbackIfEqual?: boolean;
}): [T, Dispatch<SetStateAction<T>>, T] {
  const [state, setState] = useState(defaultValue);
  const [debouncedState, setDebouncedState] = useState(defaultValue);

  // Set debounced value
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedState(state);
      // And run debounced callback if specified
      if (debouncedCallback && (callbackIfEqual || state !== defaultValue)) {
        debouncedCallback(state);
      }
    }, wait);
    return () => {
      clearTimeout(handler);
    };
  }, [state, wait, debouncedCallback, callbackIfEqual, defaultValue]);

  // Sync default value (if sync is enabled)
  useEffect(() => {
    if (shouldSyncDefault) {
      setState(defaultValue);
    }
  }, [shouldSyncDefault, defaultValue]);

  return [state, setState, debouncedState];
}
export default useDebouncedState;
