import { DateTime } from 'luxon';

export default function getFriendlyTime(input?: Date | string | number | null | false) {
  const dateTime = !input
    ? DateTime.local()
    : typeof input === 'number'
      ? DateTime.fromMillis(input)
      : typeof input === 'string'
        ? DateTime.fromISO(input)
        : DateTime.fromJSDate(input);

  return dateTime.toLocaleString(DateTime.TIME_SIMPLE);
}
