import { useEffect, useMemo } from 'react';

import { useField, useFormikContext } from 'formik';
import groupBy from 'lodash/groupBy';
import intersection from 'lodash/intersection';
import sortBy from 'lodash/sortBy';

import useAppState from '@/contexts/appState';
import { useAllUsers } from '@/contexts/usersList';

import Error from '@/components/formElements/styled/FormError';
import Label from '@/components/formElements/styled/FormLabel';
import Select from '@/components/formElements/styled/FormSelect';

import Padding from '@/components/common/Padding';

// Props
interface Props {
  label?: string;
  optional?: boolean;
  name: string;
  disabled?: boolean;
  intercomTarget?: string;
  placeholder?: string;
}

// Component
const FormikUserSelect = ({
  label,
  optional,
  name,
  placeholder = 'Select user',
  disabled,
  intercomTarget = 'User select',
  ...rest
}: Props) => {
  const { isSubmitting, setFieldValue } = useFormikContext();
  const [field, meta] = useField<string>(name);
  // Can also pull 'groups' field from form context
  // therefore it is a required sibling field of the same form
  const [{ value: groups }] = useField<string[]>('groups');

  // App state
  const { isSingleUser } = useAppState();
  // Context data
  const allUsers = useAllUsers();

  const selectedUser = useMemo(
    () => allUsers.find(({ id }) => id === field.value),
    [allUsers, field.value]
  );

  // Subset of users available to be assigned
  // ( NOT invitation or archived
  //   + admin of some kind Or member of a selected group )
  const eligibleUsers = useMemo(
    () =>
      allUsers.filter(
        user =>
          !user.isInvitation &&
          !user.isArchived &&
          (user.isOrganizationAdmin ||
            user.isLocationAdmin ||
            !!intersection(user.groups, groups).length)
      ),
    [allUsers, groups]
  );

  // Grouped for select component
  const groupedUserOptions = useMemo(() => {
    const groupedUsers = groupBy(eligibleUsers, user =>
      user.isOrganizationAdmin || user.isLocationAdmin ? 'Admins' : 'Group members'
    );
    const options: { label: string; options: { label: string; value: string }[] }[] = [];
    for (const label in groupedUsers) {
      options.push({
        label,
        options: groupedUsers[label].map(({ id, profile }) => ({
          label: profile.name.full,
          value: id,
        })),
      });
    }
    return sortBy(options, 'label');
  }, [eligibleUsers]);

  // Reset value if user becomes unavailble/not eligible
  // ( if group has been removed, for example )
  useEffect(() => {
    if (!eligibleUsers.find(({ id }) => id === field.value)) {
      setFieldValue(name as never, '');
    }
  }, [eligibleUsers, field.value, name, setFieldValue]);

  return (
    <Label label={label} htmlFor={name} optional={optional} data-intercom-target={intercomTarget}>
      <Select
        id={name}
        name={name}
        placeholder={placeholder}
        isClearable
        isSearchable={eligibleUsers.length >= 8}
        noOptionsMessage={() => <Padding padding='4px 24px'>No matching users</Padding>}
        isDisabled={disabled || isSubmitting || isSingleUser}
        value={
          selectedUser ? { label: selectedUser.profile.name.full, value: selectedUser.id } : null
        }
        onChange={selection => setFieldValue(name, selection?.value || '')}
        onBlur={field.onBlur}
        options={groupedUserOptions}
        {...rest}
      />
      {meta.touched && !!meta.error ? <Error>{meta.error}</Error> : null}
    </Label>
  );
};
export default FormikUserSelect;
