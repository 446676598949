import { useField, useFormikContext } from 'formik';

import FormInputGroup from '@/components/formElements/styled/FormInputGroup';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactNode;
  labelIcon?: string;
  optional?: boolean;
  name: string;
  dense?: boolean;
}

const FormikInput = ({ name, disabled, ...rest }: Props) => {
  const { isSubmitting } = useFormikContext();
  const [field, meta] = useField(name);
  return (
    <FormInputGroup
      error={meta.touched ? meta.error : undefined}
      disabled={disabled || isSubmitting}
      {...rest}
      {...field}
    />
  );
};
export default FormikInput;
