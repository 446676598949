import { createContext, useContext, useMemo } from 'react';

import Doc from '@/classes/Doc';
import Organization from '@/classes/Organization';

import { db } from '@/lib/firebase';

import orderBy from 'lodash/orderBy';

import useCollection from '@/hooks/useCollection';

import useAppState from './appState';

// Context ( with hook shortcut )
const fetchingOrganizations: [Doc<Organization>[] | null, boolean] = [null, true];
const organizationsContext = createContext(fetchingOrganizations);
const useOrganizations = () => useContext(organizationsContext);
export default useOrganizations;

// Context definition w/ provider
export const OrganizationsProvider = ({ children }: { children: React.ReactNode }) => {
  const { uid } = useAppState();
  const [organizationDocs, organizationsAreFetching] = useCollection<Organization>(
    !!uid && db.collection('organizations').where('users', 'array-contains', uid)
  );
  const orderedOrganizations = useMemo(
    () => organizationDocs && orderBy(organizationDocs, 'profile.name'),
    [organizationDocs]
  );
  return (
    <organizationsContext.Provider value={[orderedOrganizations, organizationsAreFetching]}>
      {children}
    </organizationsContext.Provider>
  );
};
