import type { DefaultTheme } from 'styled-components';

import { darkSemantics, darkShadows } from './darkTheme';
import OrioleTheme, { orioleColors } from './orioleTheme';

const orioleThemeDark: DefaultTheme = {
  ...OrioleTheme,
  isDark: true,

  /////////////////////
  // Semantic Colors //
  /////////////////////
  ...darkSemantics,
  linkColor: orioleColors.primary400,
  linkHover: orioleColors.primary300,
  primaryDark: orioleColors.primary200,

  /////////////
  // Shadows //
  /////////////
  ...darkShadows,
};

export default orioleThemeDark;
