import { Suspense, lazy, useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { fbFunctions } from '@/lib/firebase';
import { httpsCallable } from 'firebase/functions';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { usePostHog } from 'posthog-js/react';
import { useIntercom } from 'react-use-intercom';

import useAuth from '@/contexts/auth';
import useCurrentRelease from '@/contexts/currentRelease';
import { SessionProvider } from '@/contexts/session';
import { StylesProvider } from '@/contexts/styles';

import AuthRoutes from '@/routes/AuthRoutes';
import UserRoutes from '@/routes/UserRoutes';

import InvitationPage from '@/components/pages/InvitationPage';
import LoadingPage from '@/components/pages/LoadingPage';

import UpdateNotebirdPrompt from '@/components/layout/UpdateNotebirdPrompt';

import ErrorSuspendPlaceholder from './components/common/ErrorSuspendPlaceholder';

// Lazy load MaintenancePage
const MaintenancePage = lazy(() => import('@/components/pages/MaintenancePage'));

// React Query Client
const queryClient = new QueryClient();

// Main app component
const App = () => {
  const currentRelease = useCurrentRelease();
  const [auth, authIsFetching] = useAuth();
  const { boot, shutdown } = useIntercom();

  // Set intercom boot state appropriately
  useEffect(() => {
    if (auth?.uid) {
      // Boot intercom with verified user
      (async () => {
        const { data: userHash } = await httpsCallable<unknown, string | undefined>(
          fbFunctions,
          'getIntercomUserHash'
        )();
        boot({
          userId: auth.uid,
          email: auth.email || '',
          userHash,
          customLauncherSelector: '.intercom-launcher',
          customAttributes: {
            is_email_verified: auth.emailVerified,
            notebird_web_version: import.meta.env.VITE_VERSION,
          },
        });
      })();
    } else if (!authIsFetching) {
      // Shutdown to clear any potential user convos
      shutdown();
      // This would launch anonymous/visitor intercom
      // but it got a little weird...
      // boot();
    }
  }, [
    auth?.displayName,
    auth?.email,
    auth?.emailVerified,
    auth?.uid,
    authIsFetching,
    boot,
    shutdown,
  ]);

  // Start session when logged in
  const posthog = usePostHog();
  const isLoggedIn = !!auth;
  useEffect(() => {
    if (!isLoggedIn) return;
    posthog?.startSessionRecording();
    return () => posthog?.stopSessionRecording();
  }, [isLoggedIn, posthog]);

  // Render maintenance mode if set
  if (currentRelease.maintenanceMode) {
    return (
      <Suspense fallback={<LoadingPage />}>
        <StylesProvider>
          <ErrorSuspendPlaceholder>
            <MaintenancePage millis={currentRelease.maintenanceEnd?.toMillis() ?? null} />
          </ErrorSuspendPlaceholder>
        </StylesProvider>
      </Suspense>
    );
  }

  // Main app if not in maintenance mode
  return (
    <StylesProvider>
      <Router>
        {/* If new version is ready, show prompt */}
        <UpdateNotebirdPrompt />

        <Routes>
          {/* Invitation page is unique in that it's the only page that */}
          {/* applies to both logged in and logged out users */}
          <Route
            path='/invitation/:organizationId/:invitationId/:token'
            element={<InvitationPage />}
          />

          {/* Non-invitation pages */}
          <Route path='*' element={<NonInvitationPages />} />
        </Routes>
      </Router>
    </StylesProvider>
  );
};
export default App;

function NonInvitationPages() {
  const [auth, authIsFetching] = useAuth();

  return (
    <>
      {/* Auth routes (when logged out) */}
      {!auth && !authIsFetching && <AuthRoutes />}

      {/* User Routes ( when logged in or optimistically fetching auth ) */}
      {(!!auth || authIsFetching) && (
        <QueryClientProvider client={queryClient}>
          <SessionProvider>
            <UserRoutes />
          </SessionProvider>
        </QueryClientProvider>
      )}
    </>
  );
}
