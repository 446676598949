import type { DefaultTheme } from 'styled-components';

import primaryTheme, { colors } from './primaryTheme';

// Orange theme
export const orioleColors = {
  ...colors,
  primary000: '#FFE8D9',
  primary100: '#FFD0B5',
  primary200: '#FFB088',
  primary300: '#FF9466',
  primary400: '#F9703E',
  primary500: '#F35627',
  primary600: '#DE3A11',
  primary700: '#C52707',
  primary800: '#AD1D07',
  primary900: '#841003',
};

const orioleTheme: DefaultTheme = {
  ...primaryTheme,
  // Image hue to rotate (to orange)
  imgFilter: 'hue-rotate(165deg) contrast(0.9) brightness(1.05) saturate(1.25)',

  /////////////////////
  // Color Overrides //
  /////////////////////
  ...orioleColors,

  /////////////////////
  // Semantic Colors //
  /////////////////////
  linkColor: orioleColors.primary500,
  linkHover: orioleColors.primary600,
  primaryDark: orioleColors.primary800,
};

export default orioleTheme;
